import React from 'react';
import Icon from '../Icons/Icon';
import styles from './AdjustItem.module.css';

const AdjustItem = ({ quantity, onChange, isTransparent }) => {
  const handleIncrement = () => onChange(quantity + 1); // Increase quantity
  const handleDecrement = () => {
    if (quantity > 1) {
      onChange(quantity - 1); // Decrease quantity if > 1
    }
  };

  const handleOnChange = (e) => {
    const num = parseInt(e.target.value, 10);
    if (!isNaN(num) && num > 0) {
      onChange(num); // Only update if the number is valid and > 0
    }
  };

  return (
    <div className={`${styles.root} ${isTransparent ? styles.transparent : ''}`}>
      <div
        className={styles.iconContainer}
        role="presentation"
        onClick={handleDecrement}
      >
        <Icon symbol="minus" />
      </div>
      <div className={styles.inputContainer}>
        <input
          className={isTransparent ? styles.transparentInput : ''}
          onChange={handleOnChange}
          type="number"
          value={quantity} // Use quantity prop instead of internal state
          min="1" // Ensure a minimum quantity of 1
        />
      </div>
      <div
        role="presentation"
        onClick={handleIncrement}
        className={styles.iconContainer}
      >
        <Icon symbol="plus" />
      </div>
    </div>
  );
};

export default AdjustItem;
