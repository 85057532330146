import React from 'react';
import Icon from '../Icons/Icon';
import styles from './RemoveItem.module.css';

const RemoveItem = ({ onClick }) => {
  return (
    <div className={styles.root} onClick={onClick} role="button" tabIndex="0" onKeyPress={(e) => e.key === 'Enter' && onClick()}>
      <Icon symbol={'cross'} />
    </div>
  );
};

export default RemoveItem;
