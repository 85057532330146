import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import AddItemNotificationContext from '../../context/AddItemNotificationProvider';
import Button from '../Button';
import Icon from '../Icons/Icon';
import styles from './AddNotification.module.css';

const AddNotification = (props) => {
  const { state } = useContext(AddItemNotificationContext); // Access product info from context
  const showNotif = state?.open;
  const product = state?.product; // Product data from context
  
  if (!product) {
    return null; // If no product data, don't render notification
  }

  return (
    <div
      className={`${styles.root} ${showNotif ? styles.show : styles.hide}`}
      aria-live="polite"
    >
      <div className={styles.header}>
        <div className={styles.iconContainer}>
          <Icon symbol={'check'} />
        </div>
        <span>Item added to bag</span>
      </div>

      <div className={styles.newItemContainer}>
        <div className={styles.imageContainer}>
          <img src={product.imagen} alt={product.imageAlt} />
        </div>
        <div className={styles.detailContainer}>
          <span className={styles.name}>{product.nombre}</span>
          <span className={styles.meta}>Tamaño: {product.tamanio || 'N/A'}</span>
          <span className={styles.meta}>Tipo: {product.tipo_perfume || 'N/A'}</span>
        </div>
      </div>

      <div className={styles.actionContainer}>
        <Button onClick={props.openCart} level={'secondary'}>
          Ver bolsa (1)
        </Button>
        <Button level="primary" href="/cart">
          Finalizar compra
        </Button>
        <div className={styles.linkContainer}>
          <Link to={'/shop'}>Continuar comprando</Link>
        </div>
      </div>
    </div>
  );
};

export default AddNotification;
