import React, { useState, useEffect } from 'react';

import Drawer from '../Drawer';
import QuickView from '../QuickView';
import CurrencyFormatter from '../CurrencyFormatter';
import styles from './FavoriteCard.module.css';

const FavoriteCard = ({ id, handleRemoveClick }) => {
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showQuickView, setShowQuickView] = useState(false);
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchProductDetails = async () => {
      try {
        const response = await fetch(`${apiUrl}/api/Producto/${id}`);
        if (!response.ok) throw new Error('Failed to fetch product');
        const data = await response.json();
        setProduct(data);
      } catch (error) {
        console.error('Error fetching product:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchProductDetails();
  }, [id]);


  if (loading) return <p>Loading...</p>;
  if (!product) return <p>Product not found.</p>;

  return (
    <div className={styles.root}>
      <div>
        <div className={styles.imageContainer}>
          <img src={product.imagen} alt={product.nombre} />
        </div>
        <div className={styles.detailsContainer}>
          <span className={styles.productName}>{product.nombre}</span>
          <div></div>
          <span className={styles.productCasa}>{product.casa}</span>
          <div></div>
          <span className={styles.meta}>{product.tipo_perfume}</span>
          <div className={styles.prices}>
            <span
              className={`${product.precio_detalle ? styles.salePrice : ''}`}
            >
              <CurrencyFormatter amount={product.precio_detalle} />
            </span>
          </div>
        </div>
        
      </div>
      
      <div className={styles.actionContainer}>
        {/*
        <span role="presentation" onClick={() => setShowQuickView(true)}>
          Edit
        </span>
        */}
        <span role="presentation" onClick={() => handleRemoveClick(id)}>
          Remove
        </span>
      </div>
      
      {/*
      <Drawer visible={showQuickView} close={() => setShowQuickView(false)}>
        <QuickView
          product={product}
          buttonTitle="Update Favorite"
          close={() => setShowQuickView(false)}
        />
      </Drawer>
      */}
    </div>
  );
};

export default FavoriteCard;
