import { useCart } from '../context/CartContext';  // Import useCart hook to access cart data
import CartItem from '../components/CartItem';
import OrderSummary from '../components/OrderSummary';
import styles from './cart.module.css';
import { useNavigate, Link } from 'react-router-dom';
import Icon from '../components/Icons/Icon';
import Brand from '../components/Brand';
import Footer from '../components/Footer';
import Container from '../components/Container';
import useAuth from '../hooks/useAuth';

const CartPage = (props) => {
  const navigate = useNavigate();
  const { cartItems } = useCart();
  const totalPrice = cartItems.reduce((total, item) => total + (item.precio_detalle * item.quantity), 0);

  const handleLogout = () => {
    // Remove the token from localStorage
    localStorage.removeItem('authToken');

    // Redirect to the login page
    navigate('/login');
  };

  // Call the useAuth hook to check authentication state
  const authLoading = useAuth(); // Get loading state from useAuth

  // Early return if authLoading is true
  if (authLoading) {
    return <p>Loading...</p>; // You can show a loading spinner or message here
  }
  
  return (
    <div>
      <div className={styles.contentContainer}>
        <Container size={'large'} spacing={'min'}>
          <div className={styles.headerContainer}>
            <div className={styles.shoppingContainer}>
              <Link className={styles.shopLink} to={'/shop'}>
                <Icon symbol={'arrow'}></Icon>
                <span className={styles.continueShopping}>
                  Continuar comprando
                </span>
              </Link>
            </div>
            <Brand />
            <div className={styles.loginContainer}>
            <span 
              className={`${styles.shopLink} ${styles.logoutLabel}`}
              onClick={handleLogout} // Trigger logout on click
            >
              Cerrar sesión
            </span>
            <button
              aria-label="Logout"
              className={`${styles.iconButton} ${styles.iconContainer}`}
              onClick={handleLogout} // Correct usage of navigate
            >
              <Icon symbol={'logout'} />
            </button>
          </div>
          </div>
          <div className={styles.summaryContainer}>
            <h3>Bolsa</h3>
            <div className={styles.cartContainer}>
              <div className={styles.cartItemsContainer}>
              {cartItems.length === 0 ? (
                <p>Bolsa vacía.</p>
              ) : (
                cartItems.map(product => (
                  <CartItem 
                    key={product.id} 
                    product={product} // Pass the entire product object
                  />
                ))
              )}

              </div>
              <OrderSummary />
            </div>
          </div>
        </Container>
      </div>
      <Footer />
    </div>
  );
};

export default CartPage;
