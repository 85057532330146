import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import IndexPage from './pages/index'; // Adjust the path as necessary
import ShopPage from './pages/shop';
import CartPage from './pages/cart'; 
import LoginPage from './pages/login'; 
import SignupPage from './pages/signup'; 
import OrderPage from './pages/account/orders'; 
import FavoritesPage from './pages/account/favorites'; 
import OrderIndexPage from './pages/account/index'; 
import AccountSuccessPage from './pages/accountSuccess'; 
import SupportPage from './pages/support'; 
import OrderConfirmPage from './pages/orderConfirm';
import ProductPage from './pages/product/sample'; // Dynamic product page
import { NotificationProvider } from './context/AddItemNotificationProvider'; // Adjust the path as necessary
import { CartProvider } from './context/CartContext'; // Import the CartProvider
import { ProductProvider } from './context/ProductContext'; // Import the CartProvider
import { FavoritesProvider } from './context/FavoritesContext';
import AdminPage from './pages/admin/admin';
import ResetPasswordPage from './pages/resetPasswordPage';
import ForgotPage from './pages/forgot';
import PrivateRoute from './components/PrivateRoute/PrivateRoute';
import UploadPage from './pages/admin/uploadImage';


const App = () => {
  return (
    <NotificationProvider>
      <ProductProvider> 
        <CartProvider> 
          <FavoritesProvider>
              <Router>
                <Routes>
                  <Route path="/" element={<IndexPage />} />
                  <Route path="/shop" element={<ShopPage />} />
                  <Route path="/cart" element={<CartPage />} />
                  <Route path="/login" element={<LoginPage />} />
                  <Route path="/signup" element={<SignupPage />} />
                  <Route path="/forgot" element={<ForgotPage />} />
                  <Route path="/resetpassword" element={<ResetPasswordPage />} />
                  <Route path="/accountSuccess" element={<AccountSuccessPage />} />
                  <Route path="/orderConfirm" element={<OrderConfirmPage />} />
                  <Route path="/account" element={<OrderIndexPage />} />
                  <Route path="/account/orders" element={<OrderPage />} />
                  <Route path="/account/favorites" element={<FavoritesPage />} />
                  <Route path="/product/:id" element={<ProductPage />} /> {/* Dynamic route */}
                  <Route path="/support" element={<SupportPage />} />
                  <Route
                    path="/admin"
                    element={
                      <PrivateRoute>
                        <AdminPage />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/upload"
                    element={
                      <PrivateRoute>
                        <UploadPage />
                      </PrivateRoute>
                    }
                  />
                  
                </Routes>
              </Router>
            </FavoritesProvider>,
        </CartProvider>
      </ProductProvider>
    </NotificationProvider>
  );
};

export default App;
