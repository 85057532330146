import React, { useState } from 'react';
import { jwtDecode } from 'jwt-decode';
import styles from './ProductCardGrid.module.css';

import Drawer from '../Drawer';
import ProductCard from '../ProductCard';
import QuickView from '../QuickView';
import Slider from '../Slider';

const ProductCardGrid = ({ data, height, columns = 3, spacing, showSlider = false }) => {
  const [showQuickView, setShowQuickView] = useState(false);

  const token = localStorage.getItem('authToken');
  let userEmail = null;

  if (token) {
    try {
      const decodedToken = jwtDecode(token);
      userEmail = decodedToken.nameid; // Adjust if email is stored under another key
    } catch (error) {
      console.error('Error decoding JWT:', error);
    }
  }

  const columnCount = {
    gridTemplateColumns: `repeat(${columns}, 1fr)`,
  };

  const renderCards = () => {
    return data.map((product) => (
      <ProductCard
        key={product.id}
        id={product.id}
        height={height}
        precio_detalle={product.precio_detalle}
        description={product.descripcion}
        imageAlt={product.alt}
        name={product.nombre}
        casa={product.casa}
        image={product.imagen}
        tipo_perfume={product.tipo_perfume}
        precio_mayor={product.precio_mayor}
        userEmail={userEmail} // Pass userEmail to ProductCard
        showQuickView={() => setShowQuickView(true)}
      />
    ));
  };

  return (
    <div className={styles.root} style={columnCount}>
      <div
        className={`${styles.cardGrid} ${
          showSlider === false ? styles.show : ''
        }`}
        style={columnCount}
      >
        {data && renderCards()}
      </div>

      {showSlider && (
        <div className={styles.mobileSlider}>
          <Slider spacing={spacing}>{data && renderCards()}</Slider>
        </div>
      )}

      <Drawer visible={showQuickView} close={() => setShowQuickView(false)}>
        <QuickView close={() => setShowQuickView(false)} />
      </Drawer>
    </div>
  );
};

export default ProductCardGrid;
