import React from 'react';
import styles  from './accountSuccess.module.css';

import ActionCard from '../components/ActionCard';
import Container from '../components/Container';
import Layout from '../components/Layout/Layout';

const AccountSuccessPage = (props) => {
  return (
    <Layout disablePaddingBottom>
      <Container size={'medium'}>
        <div className={styles.root}>
          <h1>Cuenta Creada</h1>
          <p>
            Te enviamos un correo de confirmación. Revisa tu correo electrónico para completar la creación de la cuenta y poder disfutar de nuestras fragancias.
          </p>
          <div className={styles.actionContainer}>
            <ActionCard
              title={'Cuenta'}
              icon={'user'}
              subtitle={'Echa un vistazo a tu carrito'}
              link={'/cart'}
            />

            <ActionCard
              title={'Tienda'}
              icon={'bag'}
              subtitle={'Continúa comprando...'}
              link={'/shop'}
            />
          </div>
        </div>
      </Container>
    </Layout>
  );
};

export default AccountSuccessPage;
