import React, { useState, useContext, useEffect, Suspense } from 'react';
import styles from './sample.module.css';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import useIsAuthenticated from '../../hooks/useIsAuthenticated';

import Accordion from '../../components/Accordion';
import AdjustItem from '../../components/AdjustItem';
import Button from '../../components/Button';
import Breadcrumbs from '../../components/Breadcrumbs';
import Container from '../../components/Container';
import CurrencyFormatter from '../../components/CurrencyFormatter';
import Layout from '../../components/Layout/Layout';
import HoverList from '../../components/HoverList';
import NotificationContext from '../../context/AddItemNotificationProvider'; 
import { useCart } from '../../context/CartContext'; 
import { newtonsCradle } from "ldrs"; // Import the animation

const ProductPage = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const { state, showNotification } = useContext(NotificationContext);
  const [qty, setQty] = useState(1); // Start with a quantity of 1
  const [isWishlist, setIsWishlist] = useState(false);
  const isAuthenticated = useIsAuthenticated();

  const { addToCart } = useCart();
  const { id } = useParams();
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isHoverListVisible, setIsHoverListVisible] = useState(false);
  newtonsCradle.register(); // Register the animation

  function Loader() {
    return (
      <div className={styles.loadingContainer}>
        <l-newtonsCradle size="50" bg-opacity="0.5" speed="1.0" color="grey"></l-newtonsCradle>
      </div>
    );
  }

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const response = await axios.get(`${apiUrl}/api/Producto/${id}`);
        setProduct(response.data);
      } catch (err) {
        setError('Failed to fetch product data');
      } finally {
        setLoading(false);
      }
    };

    fetchProduct();
  }, [id]);

  const handleAddToCart = () => {
    if (qty > 0 && product) {
      addToCart({ ...product, quantity: qty });
      showNotification(product);
    } else {
      showNotification('Please select a quantity');
    }
  };

  

  if (error) return <div>{error}</div>;

  return (
    <Suspense fallback={<Loader />}>
      {loading ? <Loader /> : (
        <Layout>
          <div className={styles.root}>
            <Container size={'large'} spacing={'min'}>
              <Breadcrumbs
                crumbs={[
                  { link: '/', label: 'Home' },
                  { label: `${product.genero}`, link: `/shop?q=${product.genero}` },
                  { label: `${product.casa}`, link: `/shop?q=${product.casa}` },
                  { label: `${product.nombre}` },
                ]}
              />
              <div className={styles.content}>
                <div className={styles.imageContainer}>
                  <img src={product.imagen} alt={product.imageAlt} />
                </div>
                <div className={styles.details}>
                  <h1>{product.nombre}</h1>
                  <span className={styles.vendor}> by {product.casa}</span>

                  {isAuthenticated ? (
                    <div className={styles.priceContainer}>
                      <CurrencyFormatter amount={product.precio_detalle} />
                    </div>
                  ) : (
                    <div className={styles.hiddenPrice}>
                      <span></span>
                    </div>
                  )}

                  <div></div>
                  <span className={styles.className}> Tamaño: {product.tamanio}</span>
                  <div></div>
                  <span className={styles.className}> Tipo: {product.tipo_perfume}</span>

                  <div className={styles.quantityContainer}>
                    <span>Cantidad</span>
                    {/* Pass qty and setQty as props to AdjustItem */}
                    <AdjustItem quantity={qty} onChange={setQty} />
                  </div>

                  <div className={styles.actionContainer}>
                    <div className={styles.addToButtonContainer}>
                      <Button onClick={handleAddToCart} fullWidth level={'primary'}>
                        Añadir a la bolsa
                      </Button>
                    </div>
                  </div>

                  <div className={styles.informationContainer}>
                    <Accordion type={'plus'} customStyle={styles} title={'Descripción'}>
                      <p className={styles.information}>{product.descripcion}</p>
                    </Accordion>
                    <Accordion
                      type={'plus'}
                      customStyle={styles}
                      title={'Notas olfativas'}
                      onToggle={setIsHoverListVisible}
                    >
                      <HoverList id={product.id} isVisible={isHoverListVisible} />
                    </Accordion>
                  </div>
                </div>
              </div>
              {/**
              <div className={styles.suggestionContainer}>
                <h2>You may also like</h2>
                <ProductCardGrid
                  spacing
                  showSlider
                  height={400}
                  columns={4}
                  data={suggestions}
                />
              </div>
              */}
            </Container>
          </div>
        </Layout>
      )}
    </Suspense>
  );
};

export default ProductPage;
