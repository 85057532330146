import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom'; 
import styles from './support.module.css';

import Banner from '../components/Banner';
import Contact from '../components/Contact';
import Layout from '../components/Layout/Layout';
import ThemeLink from '../components/ThemeLink';
import Policy from '../components/Policy';
import AboutUs from '../components/AboutUs';
import Container from '../components/Container';

const SupportPage = () => { 
  const navigate = useNavigate();
  const location = useLocation(); 

  const subpages = [
    { title: 'Nosotros', key: 'aboutus' },
    { title: 'Contáctanos', key: 'contact' },
    { title: 'Políticas de privacidad', key: 'policy' }
  ];

  const [current, setCurrent] = useState(subpages[0]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (location.hash) {
      const hash = location.hash.substring(1);
      const tempCurrent = subpages.find((detail) => detail.key === hash);

      if (tempCurrent && tempCurrent.key !== current.key) {
        setCurrent(tempCurrent);
        window.scrollTo(0, 475);
      }
    }
  }, [location]);

  return (
    <Layout disablePaddingBottom>
      <div className={styles.root}>
        <Banner
          maxWidth={'650px'}
          bgImage={'/Tuberose_In_Silk_EDP_-_Fragrance_Page_Banner_Image_1.webp'}
          color={'var(--standard-white)'}
          height={'350px'}
        />

        <div className={styles.navContainer}>
          {subpages.map((details) => (
            <ThemeLink
              onClick={() => navigate(`/support#${details.key}`)}
              key={details.key}
              isActive={current.key === details.key}
              to={`/support#${details.key}`}
            >
              {details.title}
            </ThemeLink>
          ))}
        </div>

        <div className={styles.pageContainer}>
          <Container size={'large'} spacing={'min'}>
            {subpages.map((details) => (
              <div
                key={details.key}
                className={`${styles.content} ${
                  current.key === details.key ? styles.show : styles.hide
                }`}
              >
                {details.key === 'aboutus' ? <AboutUs /> : details.key === 'contact' ? <Contact /> : <Policy />}
              </div>
            ))}
          </Container>
        </div>
      </div>
    </Layout>
  );
};

export default SupportPage;
