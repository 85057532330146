import { useNavigate, Link } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { useCart } from '../../context/CartContext';
import axios from 'axios';
import useIsAuthenticated from '../../hooks/useIsAuthenticated';

import Button from '../Button';
import CurrencyFormatter from '../CurrencyFormatter';
import MiniCartItem from '../MiniCartItem';

import styles from './MiniCart.module.css';

const MiniCart = () => {
  const navigate = useNavigate();
  const { cartItems } = useCart();
  const [apiData, setApiData] = useState(null);
  const isAuthenticated = useIsAuthenticated();
  const apiUrl = process.env.REACT_APP_API_URL;

  // Set totalPrice to 0 if not authenticated
  const totalPrice = isAuthenticated
    ? cartItems.reduce((total, item) => total + (item.precio_detalle * item.quantity), 0)
    : 0;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${apiUrl}/api/Producto`);
        setApiData(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <div className={styles.root}>
      <div className={styles.titleContainer}>
        <h4>Bolsa</h4>
      </div>
      <div className={styles.cartItemsContainer}>
        {cartItems.length === 0 ? (
          <p>Bolsa vacía.</p>
        ) : (
          cartItems.map(product => (
            <MiniCartItem 
              key={product.id} 
              product={product} // Pass the entire product object
            />
          ))
        )}
      </div>
      <div className={styles.summaryContainer}>
        <div className={styles.summaryContent}>
          <div className={styles.totalContainer}>
            <span>Total (CRC)</span>
            <span>
              <CurrencyFormatter amount={totalPrice} appendZero />
            </span>
          </div>
          <span className={styles.taxNotes}>
          </span>
          <Button onClick={() => navigate('/cart')} level={'primary'} fullWidth disabled={cartItems.length === 0}>
            Pasar a Finalizar compra
          </Button>
          <div className={styles.linkContainer}>
            <Link to={'/shop'}>Continuar comprando</Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MiniCart;
