import styles from './HoverList.module.css';
import React, { useEffect, useState } from 'react';

const HoverList = ({ id, isVisible }) => {
  const [attributes, setAttributes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [animate, setAnimate] = useState(false);
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    if (isVisible) {
      const fetchAttributes = async () => {
        try {
          const response = await fetch(`${apiUrl}/api/Producto_Familia_Olfativa/${id}`);
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          const data = await response.json();
          data.sort((a, b) => b.porcentaje - a.porcentaje);
          setAttributes(data);
        } catch (err) {
          setError(err.message);
        } finally {
          setLoading(false);
          setAnimate(true); // Trigger animation when accordion is opened
          setTimeout(() => setAnimate(false), 500); // Reset after animation
        }
      };

      fetchAttributes();
    }
  }, [id, isVisible]);

  const getBarColor = (familia) => {
    const colorMap = {
      "Floral": "#FF69B4",
      "Amaderado": "#8B4513",
      "Rosas": "#ff96c5",
      "Cítrico": "#FFD700",
      "Citrico": "#FFD700",
      "Oriental": "#D2691E",
      "Fresco": "#00CED1",
      "Dulce": "#FF4500",
      "Avainillado": "#ffdd75",
      "Fresco Especiado": "#C71585",
      "Fresco especiado": "#C71585",
      "Aromático": "#8A2BE2",
      "Cálido Especiado": "#A52A2A",
      "Cálido especiado": "#A52A2A",
      "Atalcado": "#b5a8a8",
      "Amielado": "#FFD700",
      "Caramelo": "#D2691E",
      "Floral Blanco": "#d1c7b2",
      "Floral blanco": "#d1c7b2",
      "Animálico": "#C8F556",
      "Cera de Abeja": "#FFD700",
      "Pachulí": "#663399",
      "Florales": "#FF1493",
      "Iris": "#5A4FCF",
      "Acuático": "#00FFFF",
      "Ozónico": "#00BFFF",
      "Violeta": "#EE82EE",
      "Terrosos": "#8B4513",
      "Nardos": "#FF6347",
      "Verde": "#32CD32",
      "Coco": "#FFE4B5",
      "Café": "#6F4F37",
      "Floral Amarillo": "#FFFF00",
      "Oud": "#8B4513",
      "Marino": "#4682B4",
      "Almizclado": "#A9A9A9",
      "Chocolate": "#D2691E",
      "Cacao": "#3E2723",
      "Ahumado": "#A9A9A9",
      "Cuero": "#8B4513",
      "Balsámico": "#D2B48C",
      // New additions
      "Especiado Suave": "#FF7F50",
      "Especiado suave": "#FF7F50",
      "Ámbar": "#FFBF00",
      "Ambar": "#FFBF00",
    };

    return colorMap[familia] || "#f2d63f"; // Default to gray if not found
};


  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  return (
    <div className={styles.list}>
      <ul>
        {attributes.map((item, index) => (
          <li
            key={item.id}
            className={`${styles.listItem} ${animate ? styles.animate : ''}`}
            style={{
              backgroundColor: getBarColor(item.familia_Olfativa),
              animationDelay: `${index * 0}s`,
              '--bar-width': `${item.porcentaje}%`,
            }}
          >
            {item.familia_Olfativa}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default HoverList;
