import React, { useState, useEffect } from 'react';
import styles  from './shop.module.css';
import axios from 'axios';  // Make sure axios is imported
import Banner from '../components/Banner';
import Container from '../components/Container';
import Layout from '../components/Layout';
import ProductCardGrid from '../components/ProductCardGrid';
import Button from '../components/Button';
import { useLocation } from 'react-router-dom';
import { newtonsCradle } from 'ldrs'
import CardController from '../components/CardController';
import Icon from '../components/Icons/Icon';
import Config from '../config.json';

const ShopPage = (props) => {
  const [allData, setAllData] = useState([]); // All products from the database
  const [filteredData, setFilteredData] = useState([]); // Filtered products based on the query
  const [displayedData, setDisplayedData] = useState([]); // Products currently displayed on the page
  const [loading, setLoading] = useState(true);  // Loading state
  const [showFilter, setShowFilter] = useState(false);

  const apiUrl = process.env.REACT_APP_API_URL;

  const location = useLocation(); // React Router hook to access location object

  newtonsCradle.register()

  function Loader() {
    return (
      <div className={styles.loadingContainer}>
        <l-newtons-cradle
          size="100"
          speed="0.8" 
          color="black" 
        ></l-newtons-cradle>
      </div>
    );
  }

  // Extract query parameters
  const getQueryParams = () => {
    const params = new URLSearchParams(location.search);
    return {
      genero: params.get('genero'), // Get the "genero" parameter
      tipo_perfume: params.get('tipo_perfume'), // Get the "tipo_perfume" parameter
      q: params.get('q'), // Get the general "q" parameter
    };
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${apiUrl}/api/Producto`); // Fetch all product data
        setAllData(response.data);
        setLoading(false); // Set loading to false when the data is fetched
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);  // Ensure loading is set to false even on error
      }
    };
  
    fetchData();
  }, []);

  // Filter and sort products based on query parameters
  useEffect(() => {

    if (loading) return;

    const { genero, tipo_perfume, q } = getQueryParams();

    const normalizeString = (str) =>
      str
        .toLowerCase() // Convert to lowercase
        .normalize('NFD') // Normalize to decomposed form
        .replace(/[\u0300-\u036f]/g, ''); // Remove diacritical marks

    const filtered = allData
      .filter((product) => {
        const matchesGenero = genero
          ? normalizeString(product.genero) === normalizeString(genero)
          : true;

        const matchesTipo_perfume =
          tipo_perfume === 'not_estuche'
            ? normalizeString(product.tipo_perfume) !== 'estuche'
            : tipo_perfume
            ? normalizeString(product.tipo_perfume) === normalizeString(tipo_perfume)
            : true;

        // Split the search query into words
        const searchWords = q ? normalizeString(q).split(' ') : [];

        // Check if any search word matches any relevant field
        const matchesSearchQuery = searchWords.every((word) =>
          normalizeString(product.nombre).includes(word) ||
          normalizeString(product.casa).includes(word) ||
          normalizeString(product.tamanio).includes(word) ||
          normalizeString(product.tipo_perfume).includes(word) ||
          normalizeString(product.genero).includes(word)
        );

        return matchesGenero && matchesTipo_perfume && matchesSearchQuery;
      })
      .sort((a, b) => {
        // Sort first by 'casa', then by 'nombre'
        const casaComparison = a.casa.localeCompare(b.casa);
        if (casaComparison !== 0) return casaComparison;
        return a.nombre.localeCompare(b.nombre);
      });

    setFilteredData(filtered); // Update the filtered data
    setDisplayedData(filtered.slice(0, 6)); // Show the first batch of filtered products
  }, [location.search, allData, loading]);

  // Handle the "Load More" button click
  const loadMoreProducts = () => {
    const nextBatch = filteredData.slice(displayedData.length, displayedData.length + 6); // Get the next batch of filtered products
    setDisplayedData([...displayedData, ...nextBatch]); // Append the new products to displayedData
  };



  return loading ? <Loader /> : (
  
    <Layout>
      <div className={styles.root}>
        <Banner
          maxWidth={'650px'}
          name={`Variedad en nuestros productos`}
          subtitle={
            'Descubre nuestra colección de perfumes, diseñada para destacar con un toque moderno en cada ocasión. Desde aromas audaces y atrevidos hasta fragancias suaves y envolventes, nuestra selección cubre cada estado de ánimo y estilo.'
          }
        />
        <Container size={'large'} spacing={'min'}>
        <div className={styles.metaContainer}>
            <span className={styles.itemCount}>476 items</span>
            <div className={styles.controllerContainer}>
              <div
                className={styles.iconContainer}
                role={'presentation'}
                onClick={() => setShowFilter(!showFilter)}
              >
                <Icon symbol={'filter'} />
                <span>Filters</span>
              </div>
              <div
                className={`${styles.iconContainer} ${styles.sortContainer}`}
              >
                <span>Sort by</span>
                <Icon symbol={'caret'} />
              </div>
            </div>
          </div>
          <CardController
            closeFilter={() => setShowFilter(false)}
            visible={showFilter}
            filters={Config.filters}
          />
          <div className={styles.productContainer}>
            <span className={styles.mobileItemCount}>{allData.length}</span>
            <ProductCardGrid data={displayedData} />
          </div>
          <div className={styles.loadMoreContainer}>
            <span>{displayedData.length} de {filteredData.length}</span>
            <Button 
              onClick={loadMoreProducts}
              visible={displayedData.length !== filteredData.length}
              level="secondary"
              >Cargar más
            </Button>
          </div>
        </Container>
      </div>
      

    </Layout>
    
  );
  
};



export default ShopPage;
