import React from 'react';
import styles from './AboutUs.module.css';

const AboutUs = () => {
  return (
    <div className={styles.root}>
      <div className={styles.section}>
        <h3>Sobre Nosotros</h3>
        <p>Desde 2019, en <strong>Perfumería y Estilo Glamour</strong>, nos dedicamos a ofrecerte fragancias de lujo, con la garantía de ser <strong>100% originales</strong>. Nuestra pasión por la perfumería nos ha convertido en una referencia para quienes buscan calidad, autenticidad y exclusividad en cada aroma.</p>
        
        <h3>Nuestra Misión</h3>
        <p>Buscamos brindarte una experiencia única al elegir tu fragancia ideal. Creemos que un perfume es más que un aroma: es una extensión de tu personalidad, una forma de expresión y un sello de identidad. Por ello, trabajamos con las mejores marcas del mundo para ofrecerte productos excepcionales.</p>
        
        <h3>Compromiso con la Calidad</h3>
        <p>Nos aseguramos de que cada fragancia cumpla con los más altos estándares de calidad. Nuestros perfumes provienen directamente de distribuidores autorizados, garantizando autenticidad en cada compra.</p>
        
        <h3>¿Por qué elegirnos?</h3>
        <ul>
          <li>Productos 100% originales.</li>
          <li>Amplia selección de marcas exclusivas.</li>
          <li>Atención personalizada para ayudarte a encontrar tu fragancia ideal.</li>
          <li>Envíos rápidos y seguros.</li>
        </ul>
        
        <h3>Contáctanos</h3>
        <p>Si deseas más información sobre nuestros productos o servicios, no dudes en comunicarte con nosotros.</p>
        <ul>
          <li>Visita nuestra página de contacto: <a href="https://www.perfumeriayestiloglamour.com/Contact" rel="external nofollow noopener" target="_blank">https://www.perfumeriayestiloglamour.com/Contact</a></li>
        </ul>
      </div>
    </div>
  );
};

export default AboutUs;