import React, { useEffect, useState } from 'react';
import styles from './orders.module.css';
import { jwtDecode } from 'jwt-decode'; // Correct import for jwt-decode

import AccountLayout from '../../components/AccountLayout/AccountLayout';
import Breadcrumbs from '../../components/Breadcrumbs';
import Layout from '../../components/Layout/Layout';
import OrderItem from '../../components/OrderItem/OrderItem';
import { useNavigate } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';

const OrderPage = (props) => {
  const navigate = useNavigate();
  const [orders, setOrders] = useState([]);
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchOrders = async () => {
      const authToken = localStorage.getItem('authToken'); // Retrieve the authToken from localStorage
      if (!authToken) {
        console.error('Auth token not found in localStorage');
        return;
      }

      try {
        const decodedToken = jwtDecode(authToken); 
        const email = decodedToken.nameid;

        if (!email) {
          console.error('Email not found');
          return;
        }

        // Fetch the orders using the email
        const response = await fetch(`${apiUrl}/api/Pedido/byCorreo/${email}`);
        const data = await response.json();
        setOrders(data);
      } catch (error) {
        console.error('Error fetching orders:', error);
      }
    };

    fetchOrders();
  }, []);


  // Call the useAuth hook to check authentication state
  const authLoading = useAuth(); // Get loading state from useAuth

  // Early return if authLoading is true
  if (authLoading) {
    return <p>Loading...</p>; // You can show a loading spinner or message here
  }
  return (
    <Layout>
      <AccountLayout>
        <Breadcrumbs
          crumbs={[
            { link: '/', label: 'Home' },
            { link: '/account', label: 'Cuenta' },
            { link: '/account/orders/', label: 'Órdenes' },
          ]}
        />
        <h1>Pedidos</h1>
        <div className={`${styles.tableHeaderContainer} ${styles.gridStyle}`}>
          <span className={styles.tableHeader}>Orden</span>
          <span className={styles.tableHeader}>Fecha</span>
          <span className={styles.tableHeader}></span>
          <span className={styles.tableHeader}>Total</span>
        </div>

        {/* Map through the orders and pass the id to OrderItem */}
        {orders.map((order) => (
          <OrderItem key={order.id} orderId={order.id} headerStyling={styles.gridStyle} />
        ))}
      </AccountLayout>
    </Layout>
  );
};

export default OrderPage;
