import React from 'react';
import { isNumeric } from '../../helpers/general';

const CurrencyFormatter = ({
  amount,
  currency = 'CRC', // Currency code for Costa Rican Colón
  appendZero = false,
  useDollar = false,
}) => {
  let displayAmount =
    (typeof amount !== 'number' && parseFloat(amount?.replace('$', ''))) ||
    amount;

  // Format the amount as a number (remove currency symbols or other non-numeric characters)
  const formatNumber = new Intl.NumberFormat('es-CR', {
    style: 'decimal',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  // Format the number part only
  let formattedAmount = formatNumber.format(displayAmount);

  // Remove the comma if the locale format uses it and replace with space
  formattedAmount = formattedAmount.replace(',', ' ');

  // Currency symbol for Costa Rican Colón (₡)
  const symbol = '₡';

  // Construct the formatted price string
  const priceComponent = (
    <>
      <span>{symbol} </span>
      <span>{formattedAmount}</span>
    </>
  );

  return isNumeric(amount) ? priceComponent : 'No price available';
};

export default CurrencyFormatter;
