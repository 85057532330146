import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styles from './admin.module.css';
import Layout from '../../components/Layout';
import Button from '../../components/Button';
import { useNavigate } from 'react-router-dom';  

const AdminPage = () => {
  const navigate = useNavigate(); 
  const [productos, setProductos] = useState([]);
  const [familiasOlfativas, setFamiliasOlfativas] = useState([]);
  const [selectedFamilias, setSelectedFamilias] = useState(productos?.familiasOlfativas || []);
  const apiUrl = process.env.REACT_APP_API_URL;
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const productsPerPage = 10;
  const [familiaInput, setFamiliaInput] = useState("");
  const token = localStorage.getItem("authToken");
  
  useEffect(() => {
    setSelectedFamilias(productos?.familiasOlfativas || []);
  }, [productos]);

  const axiosConfig = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };
  
  const handleAddFamilia = () => {
    const newFamilia = familiaInput.trim();

    if (newFamilia && !selectedFamilias.some(f => f.familia_Olfativa.toLowerCase() === newFamilia.toLowerCase())) {
      setSelectedFamilias(prevFamilias => [
        ...prevFamilias,
        { familia_Olfativa: newFamilia, porcentaje: 50 } // Ensures a new object is added
      ]);
      setFamiliaInput(""); // Clear input
    }
  };
  
  
  // Handle "Enter" key to add a familia
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleAddFamilia(); // Now it only runs when Enter is pressed
    }
  };


  const filteredProductos = productos.filter((producto) =>
    [producto.nombre, producto.casa, producto.tipo_perfume, producto.genero, producto.tamanio]
      .some((field) => field.toLowerCase().includes(searchTerm.toLowerCase()))
  );

  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const displayedProducts = filteredProductos.slice(indexOfFirstProduct, indexOfLastProduct);
  

  const [formState, setFormState] = useState({
    id: '',  // Added id to track the product being edited
    nombre: '',
    tipo: '',
    casa: '',
    precio: '',
    precioMayor: '',
    genero: '',
    tamano: '',
    descripcion: '',
    imagen: '',
    familiasOlfativas: []
  });

  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    axios.get(`${apiUrl}/api/Producto`)
      .then(response => setProductos(response.data))
      .catch(error => console.error('Error fetching products:', error));
    axios.get(`${apiUrl}/api/Producto_Familia_Olfativa`)
      .then(response => setFamiliasOlfativas(response.data))
      .catch(error => console.error('Error fetching families:', error));
  }, []);

  const tipoOptions = [
    "Eau de Parfum",
    "Eau de Toilette",
    "Eau de Cologne",
    "Eau Fraiche",
    "Parfum",
    "Eau Forte",
    "Elixir"
  ];

  const generoOptions = [
    "Femenino",
    "Masculino",
    "Unisex"
  ];

  const tamanioOptions = [
    "5 ml",
    "10 ml",
    "20 ml",
    "30 ml",
    "50 ml",
    "60 ml",
    "65 ml",
    "75 ml",
    "80 ml",
    "90 ml",
    "100 ml",
    "120 ml",
    "150 ml",
    "180 ml",
    "200 ml",
    "250 ml"
  ];

  const handleInputChange = (e) => {
    setFormState({ ...formState, [e.target.name]: e.target.value });
  };

  const handleFamiliaChange = (e) => {
    const selectedId = e.target.value;
    if (!selectedFamilias.some(f => f.familia_Olfativa === selectedId)) {
      setSelectedFamilias([...selectedFamilias, { familia_Olfativa: selectedId, porcentaje: 50 }]);
    }
  };

  const handlePorcentajeChange = (index, value) => {
    const updatedFamilias = [...selectedFamilias];
    updatedFamilias[index].porcentaje = value;
    setSelectedFamilias(updatedFamilias);
  };

  const handleAddProducto = () => {
    const formattedData = {
      nombre: formState.nombre,
      tipo_perfume: formState.tipo,
      casa: formState.casa,
      precio_detalle: formState.precio,
      genero: formState.genero,
      tamanio: formState.tamano,
      descripcion: formState.descripcion,
      imagen: formState.imagen,
    };

    axios.post(`${apiUrl}/api/Producto`, formattedData, axiosConfig)
    .then(response => {
      console.log('API Response:', response.data); // Log full response

      const productoId = response.data.id;
      
      if (!productoId) {
        throw new Error('Producto ID is missing from the response');
      }

      setProductos([...productos, response.data]);
      setFormState({ id: '', nombre: '', tipo: '', casa: '', precio: '', genero: '', tamano: '', descripcion: '', imagen: '' });
      setSelectedFamilias([]);

      const familiaRequests = selectedFamilias.map(familia =>
        axios.post(`${apiUrl}/api/Producto_Familia_Olfativa`, {
          producto_id: productoId,
          familia_Olfativa: familia.familia_Olfativa,
          porcentaje: familia.porcentaje
        }, axiosConfig)
      );

      return Promise.all(familiaRequests);
    })
    .catch(error => {
      console.error('Error adding product or familias olfativas:', error.response?.data || error.message);
    });

  };

  const handleEditProducto = (productoId) => {
    // Fetch product data
    const producto = productos.find(p => p.id === productoId);
    if (!producto) return;
  
    setFormState({
      id: producto.id,
      nombre: producto.nombre,
      tipo: producto.tipo_perfume,
      casa: producto.casa,
      precio: producto.precio_detalle,
      genero: producto.genero,
      tamano: producto.tamanio,
      descripcion: producto.descripcion,
      imagen: producto.imagen,
    });
  
    setIsEditing(true);
  
    // Fetch familias olfativas for this product
    axios.get(`${apiUrl}/api/Producto_Familia_Olfativa/${productoId}`)
      .then(response => {
        setSelectedFamilias(response.data);  // Populate familias olfativas in form
      })
      .catch(error => console.error('Error fetching familias olfativas:', error));
  };
  
  

  const handleUpdateProducto = () => {
    const updatedData = {
      nombre: formState.nombre,
      tipo_perfume: formState.tipo,
      casa: formState.casa,
      precio_detalle: formState.precio,
      genero: formState.genero,
      tamanio: formState.tamano,
      descripcion: formState.descripcion,
      imagen: formState.imagen,
    };
  
    axios
      .delete(`${apiUrl}/api/Producto_Familia_Olfativa/${formState.id}`, axiosConfig)  // Delete existing familias olfativas
      .then(() => {
        // Step 2: Update the product
        return axios.put(`${apiUrl}/api/Producto/${formState.id}`, updatedData, axiosConfig);
      })
      .then(() => {
        // Step 3: Add the updated familias olfativas
        const familiaRequests = selectedFamilias.map(familia =>
          axios.post(`${apiUrl}/api/Producto_Familia_Olfativa`, {
            producto_id: formState.id,
            familia_Olfativa: familia.familia_Olfativa,
            porcentaje: familia.porcentaje
          }, axiosConfig)
        );
        return Promise.all(familiaRequests);
      })
      .then(() => {
        // Step 4: Fetch the updated product list
        return axios.get(`${apiUrl}/api/Producto`);
      })
      .then(response => {
        setProductos(response.data);  // Refresh product list
        setFormState({
          id: '',
          nombre: '',
          tipo: '',
          casa: '',
          precio: '',
          genero: '',
          tamano: '',
          descripcion: '',
          imagen: ''
        });
        setSelectedFamilias([]);  // Reset selected familias
        setIsEditing(false);  // Exit edit mode
      })
      .catch(error => console.error('Error updating product or familias olfativas:', error));

  };
  
  

  const handleDeleteProducto = (id) => {
    axios.delete(`${apiUrl}/api/Producto/${id}`, axiosConfig)
      .then(() => {
        setProductos(productos.filter(Producto => Producto.id !== id));
      })
      .catch(error => console.error('Error deleting product:', error));
  };

  const handleRemoveFamilia = (index) => {
    setSelectedFamilias(prevFamilias => prevFamilias.filter((_, i) => i !== index));
  };

  const redirectToAdmin = () => {
    navigate('/upload');  // Redirect to /admin page
  };

  return (
    <Layout>
      <div className={styles.adminFormContainer}>
        <h1 className={styles.adminTitle}>Administrar Productos</h1>
        <form className={styles.adminForm} onSubmit={(e) => e.preventDefault()}>
          <div>
            <label>Nombre:</label>
            <input type="text" name="nombre" value={formState.nombre} onChange={handleInputChange} />
          </div>
          <div>
            <label>Tipo:</label>
            <select name="tipo" value={formState.tipo} onChange={handleInputChange}>
              <option value="">Seleccione un tipo</option>
              {tipoOptions.map((tipo, index) => (
                <option key={index} value={tipo}>{tipo}</option>
              ))}
            </select>
          </div>
          <div>
            <label>Casa:</label>
            <input type="text" name="casa" value={formState.casa} onChange={handleInputChange} />
          </div>
          <div>
            <label>Precio:</label>
            <input type="number" name="precio" value={formState.precio} onChange={handleInputChange} />
          </div>
          <div>
            <label>Género:</label>
            <select name="genero" value={formState.genero} onChange={handleInputChange}>
              <option value="">Seleccione un género</option>
              {generoOptions.map((genero, index) => (
                <option key={index} value={genero}>{genero}</option>
              ))}
            </select>
          </div>
          <div>
            <label>Tamaño:</label>
            <select name="tamano" value={formState.tamano} onChange={handleInputChange}>
              <option value="">Seleccione un tamaño</option>
              {tamanioOptions.map((tamano, index) => (
                <option key={index} value={tamano}>{tamano}</option>
              ))}
            </select>
          </div>
          <div>
            <label>Descripción:</label>
            <textarea name="descripcion" value={formState.descripcion} onChange={handleInputChange} />
          </div>
          <div>
            <label>Imagen URL:</label>
            <input type="text" name="imagen" value={formState.imagen} onChange={handleInputChange} />
          </div>
          <div>
          <label>Familias Olfativas:</label>
          <input
            type="text"
            value={familiaInput}
            onChange={(e) => setFamiliaInput(e.target.value)}
            onKeyDown={handleKeyDown}
          />
          <button onClick={handleAddFamilia}>➕ Agregar</button>
          </div>
          
          <ul>
            {selectedFamilias.map((familia, index) => (
              <li key={index}>
                {familia.familia_Olfativa} - 
                <input
                  type="number"
                  value={familia.porcentaje}
                  onChange={(e) => handlePorcentajeChange(index, e.target.value)}
                />
                <button onClick={() => handleRemoveFamilia(index)}>❌</button>
              </li>
            ))}
          </ul>


          <button type="button" onClick={isEditing ? handleUpdateProducto : handleAddProducto}>
            {isEditing ? 'Actualizar Producto' : 'Agregar Producto'}
          </button>
        </form>
        <div className={styles.adminTableContainer}>
          <h2 className={styles.subtitle}>Lista de Productos</h2>

          {/* Search Bar */}
          <input
            type="text"
            placeholder="Buscar producto..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className={styles.searchInput}
          />

          <div className="paginationContainer">
            <button 
              className={styles.paginationButton}
              onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
              disabled={currentPage === 1}
            >
              ⬅️ Anterior
            </button>

            <span className="paginationText"> Página {currentPage}  </span>

            <button 
              className={styles.paginationButton}
              onClick={() => setCurrentPage(prev => prev + 1)}
              disabled={indexOfLastProduct >= filteredProductos.length}
            >
              Siguiente ➡️
            </button>
          </div>


          <table className={styles.adminTable}>
            <thead>
              <tr>
                <th>Nombre</th>
                <th>Tipo</th>
                <th>Casa</th>
                <th>Precio</th>
                <th>Género</th>
                <th>Tamaño</th>
                <th>Descripción</th>
                <th>Imagen</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody>
              {displayedProducts
                .slice() // Create a copy to avoid mutating state
                .sort((a, b) => a.casa.localeCompare(b.casa)) // Sort by 'casa'
                .map((Producto) => (
                  <tr key={Producto.id}>
                    <td><a href={`/product/${Producto.id}`} className={styles.link}>{Producto.nombre}</a></td>
                    <td>{Producto.tipo_perfume}</td>
                    <td>{Producto.casa}</td>
                    <td>{Producto.precio_detalle}</td>
                    <td>{Producto.genero}</td>
                    <td>{Producto.tamanio}</td>
                    <td>{Producto.descripcion}</td>
                    <td>
                      <img src={Producto.imagen} alt={Producto.nombre} width="100" />
                    </td>
                    <td>
                      <button className={styles.editButton} onClick={() => handleEditProducto(Producto.id)}>
                        Editar
                      </button>
                      <button onClick={() => handleDeleteProducto(Producto.id)}>Eliminar</button>
                    </td>
                  </tr>
                ))}
            </tbody>

          </table>
        </div>

        {/* Button to Redirect to /admin */}
        <div className={styles.topRightButton}>
            <Button onClick={redirectToAdmin} level="secondary">
              Go to Image Page
            </Button>
        </div>

      </div>
      </Layout>
  );
};

export default AdminPage;
