import { useState, useEffect, useRef } from 'react';
import axios from 'axios';

const useIsAuthenticated = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const hasCheckedAuth = useRef(false); // Prevent duplicate calls
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    if (hasCheckedAuth.current) return; // Ensure validation runs only once
    hasCheckedAuth.current = true;

    const validateToken = async () => {
      const token = localStorage.getItem('authToken');
      if (!token) {
        setIsAuthenticated(false);
        return;
      }

      try {
        const response = await axios.get(`${apiUrl}/api/Cliente/validate-token`, {
          headers: { Authorization: `Bearer ${token}` },
        });

        setIsAuthenticated(response.status === 200);
      } catch (error) {
        console.error('Error validating token:', error);
        setIsAuthenticated(false);
      }
    };

    validateToken();
  }, [apiUrl]); // Removed isAuthenticated from dependencies

  return isAuthenticated;
};

export default useIsAuthenticated;
