import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import {
  validateEmail,
  validateStrongPassword,
  isEmpty,
} from '../helpers/general';
import styles from './signup.module.css';

import Layout from '../components/Layout/Layout';
import FormInputField from '../components/FormInputField/FormInputField';
import Button from '../components/Button';

const SignupPage = () => {
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_URL;

  const initialState = {
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    confirmPassword: '', // New field for password confirmation
  };

  const errorState = {
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    confirmPassword: '', // Error state for confirm password
  };

  const [signupForm, setSignupForm] = useState(initialState);
  const [errorForm, setErrorForm] = useState(errorState);
  const [apiError, setApiError] = useState(''); // State for API error messages

  const handleChange = (id, e) => {
    const tempForm = { ...signupForm, [id]: e };
    setSignupForm(tempForm);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let validForm = true;
    const tempError = { ...errorState };

    if (isEmpty(signupForm.firstName)) {
      tempError.firstName = 'Field required';
      validForm = false;
    }

    if (isEmpty(signupForm.lastName)) {
      tempError.lastName = 'Field required';
      validForm = false;
    }

    if (validateEmail(signupForm.email) !== true) {
      tempError.email = 'Please use a valid email address, such as user@example.com.';
      validForm = false;
    }

    if (validateStrongPassword(signupForm.password) !== true) {
      tempError.password = 'Password must have at least 8 characters, 1 lowercase, 1 uppercase and 1 numeric character.';
      validForm = false;
    }

    if (signupForm.password !== signupForm.confirmPassword) {
      tempError.confirmPassword = 'Passwords do not match.';
      validForm = false;
    }

    if (validForm) {
      try {
        const payload = {
          Correo_electronico: signupForm.email,
          Nombre: signupForm.firstName,
          Apellido: signupForm.lastName,
          Contrasenia: signupForm.password,
        };

        await axios.post(`${apiUrl}/api/Cliente`, payload);
        navigate('/accountSuccess');

      } catch (error) {
        if (error.response) {
          console.error('Server response:', error.response.data);
        }
        console.error('Error creating account:', error);
        setApiError('There was a problem creating the account. Please try again.');
      }
    } else {
      setErrorForm(tempError);
    }
  };

  return (
    <Layout disablePaddingBottom={true}>
      <div className={styles.root}>
        <div className={styles.signupFormContainer}>
          <div className={styles.loginBox}>
            <h1 className={styles.title}>Crear Cuenta</h1>
            <form
              noValidate
              className={styles.signupForm}
              onSubmit={handleSubmit}
            >
              <FormInputField
                id={'firstName'}
                value={signupForm.firstName}
                handleChange={handleChange}
                type={'input'}
                labelName={'Nombre'}
                error={errorForm.firstName}
              />

              <FormInputField
                id={'lastName'}
                value={signupForm.lastName}
                handleChange={handleChange}
                type={'input'}
                labelName={'Apellido'}
                error={errorForm.lastName}
              />

              <FormInputField
                id={'email'}
                value={signupForm.email}
                handleChange={handleChange}
                type={'email'}
                labelName={'Correo electrónico'}
                error={errorForm.email}
              />

              <FormInputField
                id={'password'}
                value={signupForm.password}
                handleChange={handleChange}
                type={'password'}
                labelName={'Contraseña'}
                error={errorForm.password}
              />

              {/* New password confirmation field */}
              <FormInputField
                id={'confirmPassword'}
                value={signupForm.confirmPassword}
                handleChange={handleChange}
                type={'password'}
                labelName={'Confirmar Contraseña'}
                error={errorForm.confirmPassword}
              />

              <Button fullWidth type={'submit'} level={'primary'}>
                Crear Cuenta
              </Button>

              {apiError && <p style={{ color: 'red' }}>{apiError}</p>}

              
            </form>
          </div>  
          <span className={styles.reminder}>Posee una cuenta?</span>
              <Button
                type={'button'}
                onClick={() => navigate('/login')}
                fullWidth
                level={'secondary'}
                className={styles.createButton}
              >
                Ingresar
              </Button>
        </div>
      </div>
    </Layout>
  );
};

export default SignupPage;
