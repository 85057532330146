import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const useAuth = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true); // Track loading state
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const validateToken = async () => {
      const token = localStorage.getItem('authToken');

      if (!token) {
        navigate('/login'); // Redirect to login if no token is found
        return;
      }

      try {
        const response = await axios.get(`${apiUrl}/api/Cliente/validate-token`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.status !== 200) {
          throw new Error('Invalid token');
        }
      } catch (error) {
        console.error('Authentication error:', error);
        //localStorage.removeItem('authToken'); // Remove invalid token
        navigate('/login'); // Redirect to login if token is invalid
      } finally {
        setLoading(false); // Set loading to false once the validation completes
      }
    };

    validateToken();
  }, [navigate]);

  return loading; // Return loading state to handle UI changes
};

export default useAuth;
