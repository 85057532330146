import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import CurrencyFormatter from '../CurrencyFormatter';
import Icon from '../Icons/Icon';
import Button from '../../components/Button';
import styles  from './OrderItem.module.css';
import { useCart } from '../../context/CartContext'; 

const OrderItem = ({ orderId, headerStyling }) => {
  const [orderDetails, setOrderDetails] = useState(null);
  const apiUrl = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();
  const [collapsed, setCollapsed] = useState(false);
  const { addToCart } = useCart();


  useEffect(() => {
    const fetchOrderDetails = async () => {
      try {
        const response = await fetch(`${apiUrl}/api/Pedido/WithItems/${orderId}`);
        const data = await response.json();
        setOrderDetails(data); // Set the order details with items
      } catch (error) {
        console.error('Error fetching order details:', error);
      }
    };

    if (orderId) {
      fetchOrderDetails();
    }
  }, [orderId]);

  if (!orderDetails) {
    return <div>Loading...</div>;
  }

  let computedTotal = 0;
  // Calculate total from orderDetails.items
  for (let x = 0; x < orderDetails.items.length; x++) {
    computedTotal += orderDetails.items[x].price * orderDetails.items[x].quantity;
  }

  const pad = (str, max) => {
    str = str.toString();
    return str.length < max ? pad('0' + str, max) : str;
  };

  const handleReorder = async () => {
    if (orderDetails.items && orderDetails.items.length > 0) {
      for (let item of orderDetails.items) {
        try {
          // Fetch the product details by its ID
          const response = await fetch(`${apiUrl}/api/Producto/${item.id_PRODUCTO}`);
          const product = await response.json();
          
          // Add the fetched product to the cart
          addToCart({ ...product, quantity: item.quantity });
        } catch (error) {
          console.error('Error fetching product details:', error);
        }
      }
    }
  };
  

  return (
    <div
      className={`${styles.root} ${
        collapsed === true ? styles.paddingBottom : ''
      }`}
    >
      <div
        className={`${headerStyling} ${styles.orderHeader}`}
        role={'presentation'}
        onClick={() => setCollapsed(!collapsed)}
      >
        
        <div className={styles.orderMeta}>
          <span className={styles.orderId}>#{pad(orderDetails.id, 5)}</span>
          <span
            className={styles.orderTotalMeta}
          >{`Productos: ${orderDetails.items.length}`}</span>
        </div>
        <div className={styles.od}>
          <span className={styles.mobileLabel}>Fecha</span>
          <span className={styles.orderDate}>
            {new Date(orderDetails.fecha).toLocaleDateString('es-ES', {
              weekday: 'short', // Short day of the week
              year: 'numeric', // Full year
              month: 'long', // Abbreviated month
              day: 'numeric', // Day of the month
            })}
          </span>
        </div>
        <div className={styles.orderMeta}>
        </div>
        <div className={styles.orderMeta}>
          <span className={styles.address}>
            <CurrencyFormatter amount={computedTotal}></CurrencyFormatter>
          </span>
        </div>
        

        <div
          className={`${styles.toggleContainer} ${
            collapsed === true ? styles.rotate : ''
          }`}
        >
          <Icon symbol={'caret'} />
        </div>
      </div>
      <div
        className={`${styles.detailsContainer} ${
          collapsed === false ? styles.hide : styles.show
        }`}
      >
        {orderDetails.items?.map((item, index) => (
          <div className={styles.itemContainer} key={index}>
            <div role={'presentation'} onClick={() => navigate(`/product/${item.id_PRODUCTO}`)} className={styles.imageContainer}>
              <img alt={item.name} src={item.image}></img>
            </div>
            <div>
              <span className={styles.itemName}>{item.name}</span>
              <div className={styles.orderItemMeta}>
                <span className={styles.itemQuantity}>Cantidad: {item.quantity}</span>
                <div className={styles.itemTotalMobile}>
                </div>
              </div>
            </div>
            <div className={styles.itemTotal}>
              <CurrencyFormatter amount={item.quantity * item.price} />
            </div>
          </div>
        )) || <div>No items found</div>}

        <div className={styles.transactionContainer}>
        <div className={styles.addToButtonContainer}>
            {/* Conditionally render the button based on collapsed state */}
            {collapsed && (
              <Button onClick={handleReorder} level={'secondary'}>
                Volver a ordenar
              </Button>
            )}
          </div>
          <div className={styles.transactionDetails}>
            <div className={styles.transactionalGrid}>
              <span>Subtotal:</span>
              <span>
                <CurrencyFormatter amount={computedTotal} />
              </span>
              <span className={styles.bold}>Total</span>
              <span className={styles.grandTotal}>
                <CurrencyFormatter amount={computedTotal} />
              </span>
            </div>
          </div>
        </div>


        
      </div>
    </div>
  );
};

export default OrderItem;