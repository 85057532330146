import React from 'react';
import { useNavigate } from 'react-router-dom';

import styles from './Brand.module.css';

const Brand = (props) => {
  const navigate = useNavigate();

  return (
    <div
      className={styles.root}
      role="presentation"
      onClick={() => navigate('/')}
    >
      {/* Replace the SVG with an image */}
      <img
        src="/logo.avif"
        alt="Estilo & Glamour"
        className={styles.logo}
      />
    </div>
  );
};

export default Brand;
