import React from 'react';
import styles  from './Slider.module.css';
import Slick from 'react-slick';

const Slider = (props) => {
  const { children, settings, spacing } = props;

  const customPaging = () => {
    return <div className={styles.mobilePagination}></div>;
  };

  const defaultSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3, // Default for larger screens
    slidesToScroll: 1,
    arrows: false,
    dotsClass: `${styles.customDots}`,
    customPaging: customPaging,
    autoplay: true, // Enable autoplay
    autoplaySpeed: 2500, // Time in milliseconds between each slide 
    responsive: [
      {
        breakpoint: 800, // At 800px or below
        settings: {
          slidesToShow: 1, // Show one slide at a time
        },
      },
    ],
  };
  

  return (
    <div className={`${styles.root} ${spacing === true ? styles.spacing : ''}`}>
      <Slick {...defaultSettings} {...settings}>
        {children}
      </Slick>
    </div>
  );
};

export default Slider;
