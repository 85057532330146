import React, { useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

const PrivateRoute = ({ children }) => {
  const [role, setRole] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const API_URL = process.env.REACT_APP_API_URL;
  const location = useLocation();

  useEffect(() => {
    const checkAuthorization = async () => {
      const token = localStorage.getItem('authToken'); // Read inside effect
      if (!token) {
        setRole('client');
        setIsLoading(false);
        return;
      }

      try {
        const response = await fetch(`${API_URL}/api/Cliente/3Dtu26oatSart3D17410420884@772`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        setRole(response.ok ? 'admin' : 'client');
      } catch (error) {
        console.error('Error fetching role:', error);
        setRole('client');
      } finally {
        setIsLoading(false);
      }
    };

    checkAuthorization();
  }, []); // Empty array ensures it runs only once

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (role !== 'admin') {
    return <Navigate to="/login" state={{ from: location }} />;
  }

  return children;
};

export default PrivateRoute;
