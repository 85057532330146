import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const OrderIndexPage = (props) => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate('/account/orders/');
  }, []);

  return <React.Fragment />;
};

export default OrderIndexPage;
