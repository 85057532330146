import React from 'react';
import { useNavigate } from 'react-router-dom';
import AdjustItem from '../AdjustItem';
import CurrencyFormatter from '../CurrencyFormatter';
import RemoveItem from '../RemoveItem';
import { useCart } from '../../context/CartContext';
import useIsAuthenticated from '../../hooks/useIsAuthenticated';


import styles from './MiniCartItem.module.css';

const MiniCartItem = ({ product }) => {
  const navigate = useNavigate();
  const { updateCartItemQuantity, removeFromCart } = useCart(); // Use context functions

  const { id, imagen, imageAlt, nombre, precio_detalle, tipo_perfume, tamanio, quantity } = product;

  const isAuthenticated = useIsAuthenticated();

  const handleQuantityChange = (newQuantity) => {
    if (newQuantity > 0) {
      updateCartItemQuantity(id, newQuantity); // Update the quantity in the cart
    } else {
      removeFromCart(id); // Remove item if quantity is 0
    }
  };

  return (
    <div className={styles.root}>
      <div
        className={styles.imageContainer}
        role={'presentation'}
        onClick={() => navigate(`/product/${id}`)} // Navigate to the product page
      >
        <img src={imagen} alt={imageAlt} />
      </div>
      <div className={styles.detailsContainer}>
        <div className={styles.metaContainer}>
          <span className={styles.name}>{nombre}</span>
          {isAuthenticated ? (
            <div className={styles.priceContainer}>
              <CurrencyFormatter amount={precio_detalle} />
            </div>
          ) : (
            <div className={styles.hiddenPrice}>
              <span></span>
            </div>
          )}
          <span className={styles.meta}> {tipo_perfume}</span>
          <span className={styles.meta}>
            Tamaño:
            <span className={styles.size}>{tamanio}</span>
          </span>
        </div>
        <div className={styles.adjustItemContainer}>
          <AdjustItem quantity={quantity} onChange={handleQuantityChange} /> {/* Pass quantity and onChange */}
        </div>
      </div>
      <div className={styles.closeContainer}>
        <RemoveItem onClick={() => removeFromCart(id)} /> {/* Remove item by ID */}
      </div>
    </div>
  );
};

export default MiniCartItem;
