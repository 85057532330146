import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import { validateEmail, isEmpty } from '../helpers/general';
import styles from './login.module.css';
import Layout from '../components/Layout/Layout';
import ReCAPTCHA from 'react-google-recaptcha'; // Import the ReCAPTCHA component
import FormInputField from '../components/FormInputField/FormInputField';
import Button from '../components/Button';

const LoginPage = () => {
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_URL;
  const initialState = {
    email: '',
    password: '',
  };

  const errorState = {
    email: '',
    password: '',
  };

  const [loginForm, setLoginForm] = useState(initialState);
  const [errorForm, setErrorForm] = useState(errorState);
  const [apiError, setApiError] = useState(''); // State for API error messages.
  const [recaptchaToken, setRecaptchaToken] = useState(null); // State for reCAPTCHA token

  const handleChange = (id, e) => {
    const tempForm = { ...loginForm, [id]: e };
    setLoginForm(tempForm);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let validForm = true;
    const tempError = { ...errorState };

    // Validation
    if (validateEmail(loginForm.email) !== true) {
      tempError.email = "Utiliza un correo válido, por ejemplo user@example.com.";
      validForm = false;
    }

    if (isEmpty(loginForm.password) === true) {
      tempError.password = "Campo requerido";
      validForm = false;
    }

    if (validForm) {
      try {
        if (!recaptchaToken) {
          console.error("Failed to generate reCAPTCHA token.");
          setApiError("Error de validación reCAPTCHA. Inténtelo de nuevo.");
          return;
        }

        const payload = {
          correo_electronico: loginForm.email,
          contrasenia: loginForm.password,
          recaptchaToken: recaptchaToken, // Include the token
        };

        // Send login request to the backend
        const response = await axios.post(`${apiUrl}/api/Cliente/login`, payload);
        if (response.status === 200) {
          localStorage.setItem("authToken", response.data.token);
          navigate("/cart");
        }
      } catch (error) {
        if (error.response) {
          console.error("Server response:", error.response.data);
        }
        console.error("Error logging in:", error);
        setApiError("Correo o contraseña inválidos.");

        // Reset the form on failed login attempt
        setLoginForm(initialState);
        setErrorForm(errorState);
      }
    } else {
      setErrorForm(tempError);
    }
  };

  return (
    <Layout disablePaddingBottom={true}>
      <div className={styles.root}>
        <div className={styles.loginFormContainer}>
          <div className={styles.loginBox}>
            <h1 className={styles.loginTitle}> Iniciar sesión </h1>
            <form noValidate className={styles.loginForm} onSubmit={handleSubmit}>
              <FormInputField
                id={'email'}
                value={loginForm.email}
                handleChange={(id, e) => handleChange(id, e)}
                type={'email'}
                labelName={'Email'}
                error={errorForm.email}
              />

              <FormInputField
                id={'password'}
                value={loginForm.password}
                handleChange={(id, e) => handleChange(id, e)}
                type={'password'}
                labelName={'Password'}
                error={errorForm.password}
              />

              <ReCAPTCHA
                sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                onChange={(token) => setRecaptchaToken(token)} // Update the token
              />

              <Button fullWidth type={'submit'} level={'primary'} className={styles.formButton}>
                Continuar
              </Button>

              {apiError && <p style={{ color: 'red' }}>{apiError}</p>}
            </form>
          </div>
          <span className={styles.reminder}></span>
          <span className={styles.reminder}>¿Eres nuevo?</span>
          <Button
            type={'button'}
            onClick={() => navigate('/signup')}
            fullWidth
            level={'secondary'}
            className={styles.createButton}
          >
            Crear Cuenta
          </Button>
          <br />
          <Link to="/forgot" className={styles.link}>
            ¿Olvidaste tu contraseña?
          </Link>
        </div>
      </div>
    </Layout>
  );
};

export default LoginPage;
