import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import NotificationContext from '../../context/AddItemNotificationProvider'; 
import useIsAuthenticated from '../../hooks/useIsAuthenticated';
import FavoritesContext from '../../context/FavoritesContext'; // Import FavoritesContext

import styles from './ProductCard.module.css';
import Icon from '../Icons/Icon';
import CurrencyFormatter from '../CurrencyFormatter';
import { useCart } from '../../context/CartContext'; 

const ProductCard = (props) => {
  const [isWishlist, setIsWishlist] = useState(false);
  const navigate = useNavigate();
  const { addToCart } = useCart(); 
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { state, showNotification } = useContext(NotificationContext);
  const [qty, setQty] = useState(1);
  const isAuthenticated = useIsAuthenticated();
  const { favorites, addToFavorites, removeFromFavorites } = useContext(FavoritesContext); // Access favorites context

  const apiUrl = process.env.REACT_APP_API_URL;
  const { id, image, imageAlt, name, casa, description, precio_detalle, precio_mayor, tipo_perfume, tamanio, showQuickView, height = 580, userEmail } = props;

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const response = await axios.get(`${apiUrl}/api/Producto/${id}`);
        setProduct(response.data);
      } catch (err) {
        setError('Failed to fetch product data');
      } finally {
        setLoading(false);
      }
    }

    fetchProduct();
  }, [id]);

  useEffect(() => {
    const checkIfInFavorites = async () => {
      if (!userEmail) return; // If no email, skip the check

      try {
        // Send a GET request to check if the product is in the user's favorites
        const response = await axios.get(`${apiUrl}/api/Cliente_Favorito/${userEmail}/${id}`);

        // If the response has data, the product is in favorites
        setIsWishlist(response.data ? true : false);
      } catch (error) {
        console.error('Error checking favorites:', error);
      }
    };

    checkIfInFavorites();
  }, [userEmail, id, apiUrl]);

  const handleRouteToProduct = () => {
    navigate(`/product/${id}`);
  };

  const handleFavorite = async (e) => {
    e.stopPropagation();

    if (!userEmail) {
      navigate(`/login`);
      return;
    }

    try {
      if (isWishlist) {
        // Remove from favorites if already added
        await axios.delete(`${apiUrl}/api/Cliente_Favorito/${userEmail}/${id}`);
        setIsWishlist(false);
      } else {
        // Add to favorites
        await axios.post(`${apiUrl}/api/Cliente_Favorito`, {
          id_Producto: id,
          Correo_electronico_Cliente: userEmail,
        });
        setIsWishlist(true);
      }
    } catch (error) {
      console.error('Error toggling favorite:', error);
    }
  };

  const handleAddToCart = (e) => {
    e.stopPropagation(); 
    if (qty > 0 && product) {
      addToCart({ ...product, quantity: qty });
      showNotification(product);
    } else {
      showNotification('Selecciona una cantidad:');
    }
  };

  // Check if the product is in the favorites list
  const isInFavorites = favorites.some(fav => fav.id === id);

  return (
    <div className={styles.root}>
      <div
        className={styles.imageContainer}
        onClick={() => handleRouteToProduct()}
        role={'presentation'}
      >
        <img style={{ height: `${height}px` }} src={image} alt={imageAlt} />

        <div
          className={styles.bagContainer}
          role={'presentation'}
          onClick={(e) => handleAddToCart(e)}
        >
          <Icon symbol={'bagPlus'} />
        </div>
        <div className={styles.heartContainer} role="presentation" onClick={handleFavorite}>
          <Icon symbol="heart" />
          <div className={`${styles.heartFillContainer} ${isWishlist ? styles.show : styles.hide}`}>
            <Icon symbol="heartFill" />
          </div>
        </div>
      </div>
      <div className={styles.detailsContainer}>
        <span className={styles.productName}>{name}</span>
        <div></div>
        <span className={styles.productCasa}>{casa}</span>
        <div></div>
        <span className={styles.meta}>{tipo_perfume}</span>
        {isAuthenticated ? (
          <div className={styles.prices}>
            <span
              className={`${precio_detalle ? styles.salePrice : ''}`}
            >
              <CurrencyFormatter amount={precio_detalle} />
            </span>
          </div>
        ) : (
          <div className={styles.hiddenPrice}>
            <span></span>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProductCard;
