import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AdjustItem from '../AdjustItem';
import CurrencyFormatter from '../CurrencyFormatter';
import RemoveItem from '../RemoveItem';
import { useCart } from '../../context/CartContext';
import Drawer from '../Drawer';
import QuickView from '../QuickView';

import styles  from './CartItem.module.css';

const CartItem = ({ product }) => {
  const navigate = useNavigate();
  const { updateCartItemQuantity, removeFromCart } = useCart(); // Use context functions
  const [showQuickView, setShowQuickView] = useState(false);
  const { id, imagen, imagenAlt, nombre, precio_detalle, tipo_perfume, tamanio, quantity } = product;


  const handleQuantityChange = (newQuantity) => {
    if (newQuantity > 0) {
      updateCartItemQuantity(id, newQuantity); // Update the quantity in the cart
    } else {
      removeFromCart(id); // Remove item if quantity is 0
    }
  };

  return (
    <div className={styles.root}>
      <div
        className={styles.imageContainer}
        role={'presentation'}
        onClick={() => navigate('/product/${id}')}
      >
        <img src={imagen} alt={imagenAlt}></img>
      </div>
      <div className={styles.itemContainer}>
        <span className={styles.name}>{nombre}</span>
        <div className={styles.metaContainer}>
          <span>Tipo: {tipo_perfume}</span>
          <span>Tamaño: {tamanio}</span>
        </div>
      </div>
      <div className={styles.adjustItemContainer}>
        <AdjustItem quantity={quantity} onChange={handleQuantityChange} /> {/* Pass quantity and onChange */}
      </div>
      <div className={styles.priceContainer}>
        <CurrencyFormatter amount={precio_detalle*quantity} appendZero />
      </div>
      <div className={styles.closeContainer}>
        <RemoveItem onClick={() => removeFromCart(id)} /> {/* Remove item by ID */}
      </div>
      <Drawer visible={showQuickView} close={() => setShowQuickView(false)}>
        <QuickView close={() => setShowQuickView(false)} />
      </Drawer>
    </div>
  );
};

export default CartItem;
