import { useNavigate, Link } from 'react-router-dom';
import React from 'react';
import styles  from './AccountNav.module.css';

const AccountNav = (props) => {

  const navigate = useNavigate();
  const handleLogout = () => {
    window.localStorage.removeItem('authToken');
    navigate('/');
  };

  return (
    <div className={styles.root}>
      <div className={styles.webRoot}>
      <Link
          activeClassName={styles.activeLink}
          to={'/account/favorites'}
          className={styles.webLink}
        >
          Favoritos
        </Link>
        <Link
          activeClassName={styles.activeLink}
          to={'/account/orders/'}
          className={styles.webLink}
        >
          Órdenes
        </Link>
        <Link
          activeClassName={styles.activeLink}
          to={'/forgot'}
          className={styles.webLink}
        >
          Cambiar contraseña
        </Link>
        <span
          role={'presentation'}
          onClick={handleLogout}
          className={styles.webLink}
        >
          Cerrar sesión
        </span>
      </div>
    </div>
  );
};

export default AccountNav;
