import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styles from './resetPasswordPage.module.css';
import Layout from '../components/Layout/Layout';
import FormInputField from '../components/FormInputField/FormInputField';
import Button from '../components/Button';
import { validateStrongPassword } from '../helpers/general';

const ResetPasswordPage = () => {
  const initialState = {
    newPassword: '',
    confirmPassword: '',
  };

  const errorState = {
    newPassword: '',
    confirmPassword: '',
  };

  const [errorForm, setErrorForm] = useState(errorState);
  const [loginForm, setLoginForm] = useState(initialState);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  
  const location = useLocation();
  const navigate = useNavigate();
  const token = new URLSearchParams(location.search).get('token');

  useEffect(() => {
    if (!token) {
      setError('No token provided.');
    }
  }, [token]);

  const handleChange = (id, e) => {
    setLoginForm((prev) => ({ ...prev, [id]: e }));
  };

  const validatePasswords = () => {
    let formErrors = { ...errorState };
    let isValid = true; // Variable to track if the form is valid
  
    if (!validateStrongPassword(loginForm.newPassword)) {
      formErrors.newPassword = 'Password is too weak.';
      isValid = false; // If password is weak, form is not valid
    } else {
      formErrors.newPassword = '';
    }
  
    if (loginForm.newPassword !== loginForm.confirmPassword) {
      formErrors.confirmPassword = 'Passwords do not match.';
      isValid = false; // If passwords do not match, form is not valid
    } else {
      formErrors.confirmPassword = '';
    }
  
    setErrorForm(formErrors);
  
    return isValid; // Return whether the form is valid
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
  
    const isValid = validatePasswords(); // Check if the passwords are valid
  
    if (!isValid) {
      setError('Please fix the form errors.');
      return; // Don't proceed with the request if the form is invalid
    }
  
    if (!token) {
      setError('Token is missing.');
      return;
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/Cliente/reset-password`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ Token: token, newPassword: loginForm.newPassword }),
      });
    
      let data = null;
      if (response.headers.get('content-length') !== '0' && response.status !== 204) {
        data = await response.text(); // ✅ Use text() instead of json()
      }
    
      if (!response.ok) {
        throw new Error(data || 'Failed to reset password.');
      }
    
      setSuccess(true);
      setTimeout(() => navigate('/login'), 2000);
    } catch (error) {
      setError(error.message);
    }
    
  };

  return (
    <Layout>
      <div className={styles.root}>
        <h1 className={styles.title}>Reset Your Password</h1>
        {error && <p className={styles.error}>{error}</p>}
        {success ? (
          <p className={styles.success}>Your password has been successfully reset. Redirecting to login...</p>
        ) : (
          <form className={styles.formContainer} onSubmit={handleSubmit}>
            <FormInputField
              id="newPassword"
              value={loginForm.newPassword}
              handleChange={handleChange}
              type="password"
              labelName="Ingresar contraseña nueva"
              error={errorForm.newPassword}
            />
            <FormInputField
              id="confirmPassword"
              value={loginForm.confirmPassword}
              handleChange={handleChange}
              type="password"
              labelName="Confirmar nueva contraseña"
              error={errorForm.confirmPassword}
            />
            <div className={styles.buttonContainer}>
              <Button fullWidth level="primary" type="submit">
                Reset Password
              </Button>
            </div>
          </form>
        )}
      </div>
    </Layout>
  );
};

export default ResetPasswordPage;
