import React from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../Button';
import CurrencyFormatter from '../CurrencyFormatter';
import { useCart } from '../../context/CartContext';
import styles from './OrderSummary.module.css';

const OrderSummary = () => {
  const { cartItems } = useCart();
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_URL;
  const { clearCart } = useCart(); 

  const subtotal = cartItems.reduce((total, item) => total + item.precio_detalle * item.quantity, 0);
  const total = subtotal;

  const generateWhatsAppMessage = () => {
    let message = 'Pedido por App Web\n\n';
    cartItems.forEach(item => {
      message += `${item.quantity} - ${item.nombre} ${item.tipo_perfume} de ${item.tamanio}, ¢${item.precio_detalle}\n`;
    });
    message += `\nTotal: ¢${total}`;
    return encodeURIComponent(message);
  };

  const extractEmailFromToken = (token) => {
    try {
      const payload = JSON.parse(atob(token.split('.')[1]));
      return payload?.nameid || payload?.Correo_electronico || payload?.unique_name || null;
    } catch (error) {
      console.error('Error decoding token:', error);
      return null;
    }
  };
  

  const handleCheckoutClick = async () => {
    const token = localStorage.getItem('authToken');
    if (!token) return navigate('/login');
  
    const email = extractEmailFromToken(token);
    if (!email) return console.error('Error: No email found in token');
  
    try {
      // Step 1: Create the Order
      const orderResponse = await fetch(`${apiUrl}/api/Pedido`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          fecha: new Date().toISOString(),
          Correo_electronico_Cliente: email,
        }),
      });
      
      const responseText = await orderResponse.text(); // Read raw response
      
      console.log("Raw Response:", responseText); // Debug log
      
      if (!responseText) throw new Error("Empty response from server");
      
      const orderData = JSON.parse(responseText); // Parse only if response is not empty
      if (!orderData.id) throw new Error("Order ID missing in response");
      
      const orderId = orderData.id;
  
      // Step 2: Add Products to Order
      const productPromises = cartItems.map(item =>
        fetch(`${apiUrl}/api/Producto_Pedido`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            id_PRODUCTO: item.id,
            id_PEDIDO: orderId,
            Cantidad: item.quantity,
          }),
        })
      );
  
      await Promise.all(productPromises);
  
      // Clear the cart and update state
      clearCart(); // Reset cart immediately after checkout
  
      // Redirect to confirmation page
      navigate('/orderConfirm');
    } catch (error) {
      console.error('Error during checkout:', error);
    }
  
    // Send order details via WhatsApp
    const phoneNumber = '50688800673';
    const message = generateWhatsAppMessage();
    window.open(`https://wa.me/${phoneNumber}?text=${message}`, '_blank');
  };
  

  return (
    <div className={styles.root}>
      <div className={styles.orderSummary}>
        <span className={styles.title}>Resumen del Pedido</span>
        <div className={styles.totalContainer}>
          <span>Total: </span>
          <span><CurrencyFormatter amount={total} appendZero /></span>
        </div>
      </div>
      <div className={styles.actionContainer}>
        <Button onClick={handleCheckoutClick} fullWidth level="primary" disabled={cartItems.length === 0}>
          Finalizar compra
        </Button>
        <div className={styles.linkContainer}>
          <a href="/shop">Continuar comprando</a>
        </div>
      </div>
    </div>
  );
};

export default OrderSummary;
