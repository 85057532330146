import { useState, useEffect } from 'react';

const useRoleCheck = () => {
  const [role, setRole] = useState(null);
  const API_URL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchUserRole = async () => {
      const token = localStorage.getItem('authToken'); // Read inside effect
      if (!token) {
        setRole('client');
        return;
      }

      try {
        const response = await fetch(`${API_URL}/api/Cliente/3Dtu26oatSart3D17410420884@772`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        setRole(response.ok ? 'admin' : 'client');
      } catch (error) {
        console.error('Error fetching role:', error);
        setRole('client');
      }
    };

    fetchUserRole();
  }, [API_URL]); 

  return role;
};

export default useRoleCheck;
