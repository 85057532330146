import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './favorites.module.css';
import Button from '../../components/Button';
import Breadcrumbs from '../../components/Breadcrumbs';
import Container from '../../components/Container';
import FavoriteCard from '../../components/FavoriteCard/FavoriteCard';
import Layout from '../../components/Layout/Layout';
import Modal from '../../components/Modal';
import { jwtDecode } from 'jwt-decode';
import useIsAuthenticated from '../../hooks/useIsAuthenticated'; // Import authentication hook

const FavoritesPage = () => {
  const navigate = useNavigate();
  const [favorites, setFavorites] = useState([]);
  const [showDelete, setShowDelete] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [loading, setLoading] = useState(true);
  const apiUrl = process.env.REACT_APP_API_URL;

  const isAuthenticated = useIsAuthenticated(); // Authentication check hook

  // State to manage authentication loading
  const [authLoading, setAuthLoading] = useState(true);

  // First useEffect: Check for authentication status
  useEffect(() => {
    const checkAuthentication = async () => {
      const token = localStorage.getItem('authToken');
      if (token) {
        const decoded = jwtDecode(token);
        const email = decoded.nameid;
        
        // Call the isAuthenticated hook here if needed for more validation
        if (email) {
          setAuthLoading(false); // Set to false after checking
        } else {
          navigate('/login');
        }
      } else {
        setAuthLoading(false); // Set to false if no token is found
        navigate('/login');
      }
    };

    checkAuthentication();
  }, [navigate]);

  // Second useEffect: Fetch favorites after authentication
  useEffect(() => {
    if (isAuthenticated && !authLoading) {
      const token = localStorage.getItem('authToken');
      const decoded = jwtDecode(token);
      const email = decoded.nameid;
      fetchFavorites(email);
    }
  }, [isAuthenticated, authLoading]);

  // Fetch favorites function
  const fetchFavorites = async (email) => {
    setLoading(true);
    try {
      const response = await fetch(`${apiUrl}/api/Cliente_Favorito/${email}`);
      if (!response.ok) throw new Error('Failed to fetch favorites');

      const favoriteList = await response.json();
      const productPromises = favoriteList.map(fav =>
        fetch(`${apiUrl}/api/Producto/${fav.id_Producto}`).then(res => res.json())
      );

      const products = await Promise.all(productPromises);
      setFavorites(products);
    } catch (error) {
      console.error('Error fetching favorites:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleRemoveClick = (id) => {
    setSelectedProduct(id);
    setShowDelete(true);
  };

  const removeFromFavorites = async () => {
    if (!selectedProduct) return;
  
    try {
      const token = localStorage.getItem('authToken');
      const decoded = jwtDecode(token);
      const email = decoded.nameid;
  
      const response = await fetch(`${apiUrl}/api/Cliente_Favorito/${email}/${selectedProduct}`, {
        method: 'DELETE',
      });
  
      if (!response.ok) throw new Error('Failed to remove favorite');
  
      // Re-fetch the favorites list after a successful removal
      fetchFavorites(email);
  
      // If there are no more favorites left, reset the state to an empty list
      if (favorites.length === 1) {
        setFavorites([]);
      }
    } catch (error) {
      console.error('Error removing favorite:', error);
    } finally {
      setShowDelete(false);
      setSelectedProduct(null);
    }
  };
  

  // If the page is still loading favorites, show a loading message
  if (authLoading || loading) return <p>Loading...</p>;

  return (
    <Layout>
      <div className={styles.root}>
        <Container size="large">
          <Breadcrumbs
            crumbs={[
              { link: '/', label: 'Home' },
              { link: '/account/favorites', label: 'Favoritos' },
            ]}
          />
          <h1>Favoritos</h1>
          <div className={styles.favoriteListContainer} key={favorites.length}>
            {favorites.length > 0 ? (
              favorites.map((product) => (
                <FavoriteCard
                  key={product.id_Producto}
                  id={product.id_Producto}
                  handleRemoveClick={handleRemoveClick}
                  {...product}
                />
              ))
            ) : (
              <p>No favorites yet.</p>
            )}
          </div>
        </Container>
      </div>
      <Modal visible={showDelete} close={() => setShowDelete(false)}>
        <div className={styles.confirmDeleteContainer}>
          <h4>¿Eliminar de Favoritos?</h4>
          <p>
            ¿Estás seguro de que deseas eliminar este artículo de tus favoritos? No podrás deshacer esta acción una vez que presiones <strong>'Eliminar'</strong>
          </p>

          <div className={styles.actionContainer}>
            <Button onClick={removeFromFavorites} level="primary">Eliminar</Button>
            <Button onClick={() => setShowDelete(false)} level="secondary">Cancelar</Button>
          </div>
        </div>
      </Modal>
    </Layout>
  );
};

export default FavoritesPage;
