import React, { useState, useEffect, createRef } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import useRoleCheck from '../../hooks/useRoleCheck';
import axios from 'axios';

import AddNotification from '../AddNotification';
import Brand from '../Brand';
import Container from '../Container';
import Config from '../../config.json';
import Drawer from '../Drawer';
import ExpandedMenu from '../ExpandedMenu';
import FormInputField from '../FormInputField/FormInputField';
import Icon from '../Icons/Icon';
import MiniCart from '../MiniCart';
import MobileNavigation from '../MobileNavigation';
import styles  from './Header.module.css';

import { useCart } from '../../context/CartContext';
import { useProductContext } from '../../context/ProductContext';


const Header = (prop) => {
  const navigate = useNavigate();
  const [showMiniCart, setShowMiniCart] = useState(false);
  const [mobileMenu, setMobileMenu] = useState(false);
  const [showMenu, setShowMenu] = useState(true);

  const userRole = useRoleCheck(); // Get the role of the logged-in user

  const [menu, setMenu] = useState();
  const [activeMenu, setActiveMenu] = useState();

  const { cartItems } = useCart();

  // Calculate the number of unique product types
  const uniqueProductTypes = new Set(cartItems.map(item => item.id)); // Assuming 'id' is unique for each product
  const uniqueProductCount = uniqueProductTypes.size; // Count the unique product types

  const [showSearch, setShowSearch] = useState(false);

  const { allData } = useProductContext();
  const [search, setSearch] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [activeSuggestionIndex, setActiveSuggestionIndex] = useState(-1);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const searchRef = createRef();

  const [allProducts, setAllProducts] = useState([]); // Store all products
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(true);

  const apiUrl = process.env.REACT_APP_API_URL;

  // Fetch product data only once when the search bar mounts
  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get(`${apiUrl}/api/Producto`);
        setAllProducts(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching products:', error);
        setLoading(false);
      }
    };

    fetchProducts();
  }, []);

  const normalizeString = (str) =>
    str
      .toLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, ''); // Remove accents
  
  useEffect(() => {
    if (!search.trim()) {
      setFilteredData([]);
      return;
    }
  
    const searchWords = normalizeString(search).split(' ');
  
    const filtered = allProducts.filter((product) =>
      searchWords.every((word) =>
        normalizeString(product.nombre).includes(word) ||
        normalizeString(product.casa).includes(word) ||
        normalizeString(product.tipo_perfume).includes(word) ||
        normalizeString(product.genero).includes(word)
      )
    );
  
    setFilteredData(filtered);
  }, [search, allProducts]);
  

  const handleSelect = (product) => {
    setSearch(product.nombre);
    setFilteredData([]);
    setShowSearch(false);
    navigate(`/product/${product.id}`);
  };


  const handleHover = (navObject) => {
    if (navObject.category) {
      setShowMenu(true);
      setMenu(navObject.category);
      setShowSearch(false);
    } else {
      setMenu(undefined);
    }
    setActiveMenu(navObject.menuLabel);
  };

  // Handle input change and update suggestions
  const handleChange = (e) => {
    const input = e.target.value;
    setSearch(input);

    if (input.trim()) {
      const filteredSuggestions = allData
        .filter((product) => {
          const text = `${product.nombre} ${product.casa} ${product.tipo_perfume}`;
          return text.toLowerCase().includes(input.toLowerCase());
        })
        .slice(0, 5); // Limit suggestions to 5
      setSuggestions(filteredSuggestions);
      setShowSuggestions(true);
    } else {
      setSuggestions([]);
      setShowSuggestions(false);
    }
  };

  // Handle search submission
  const handleSearch = (e) => {
    e.preventDefault();
    if (!search.trim()) return;

    navigate(`/shop?q=${encodeURIComponent(search)}`);
    setShowSuggestions(false);
  };

  // Handle suggestion click
  const handleSuggestionClick = (suggestion) => {
    setSearch(`${suggestion.nombre}`);
    setShowSuggestions(false);
    navigate(`/shop?q=${encodeURIComponent(suggestion.nombre)}`);
  };

  // Handle keyboard navigation
  const handleKeyDown = (e) => {
    if (e.key === 'ArrowDown' && activeSuggestionIndex < suggestions.length - 1) {
      setActiveSuggestionIndex((prevIndex) => prevIndex + 1);
    } else if (e.key === 'ArrowUp' && activeSuggestionIndex > 0) {
      setActiveSuggestionIndex((prevIndex) => prevIndex - 1);
    } else if (e.key === 'Enter' && activeSuggestionIndex >= 0) {
      handleSuggestionClick(suggestions[activeSuggestionIndex]);
    }
  };


  // disable active menu when show menu is hidden
  useEffect(() => {
    if (showMenu === false) setActiveMenu(false);
  }, [showMenu]);

  // hide menu onscroll
  useEffect(() => {
    const onScroll = () => {
      setShowMenu(false);
      setShowSearch(false);
      setActiveMenu(undefined);
    };
    window.removeEventListener('scroll', onScroll);
    window.addEventListener('scroll', onScroll, { passive: true });
    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  //listen for show search and delay trigger of focus due to CSS visiblity property
  useEffect(() => {
    if (showSearch === true) {
      setTimeout(() => {
        searchRef.current.focus();
      }, 250);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showSearch]);

  return (
    <div className={styles.root}>

      <Container size={'large'} spacing={'min'}>
        {/* header container */}
        <div className={styles.header}>
          <div className={styles.linkContainer}>
            <nav
              role={'presentation'}
              onMouseLeave={() => {
                setShowMenu(false);
              }}
            >
              {Config.headerLinks.map((navObject) => {
                // Conditionally render based on role
                if (navObject.roleRequired && navObject.roleRequired !== userRole) {
                  return null;
                }
                return (
                  <Link
                    key={navObject.menuLink}
                    onMouseEnter={() => handleHover(navObject)}
                    className={`${styles.navLink} ${
                      activeMenu === navObject.menuLabel ? styles.activeLink : ''
                    }`}
                    to={navObject.menuLink}
                  >
                  {navObject.menuLabel}
                </Link>
              );
            })}
            </nav>
          </div>
          <div
            role={'presentation'}
            onClick={() => {
              setMobileMenu(!mobileMenu);
              // setDepth(0);
            }}
            className={styles.burgerIcon}
          >
            <Icon symbol={`${mobileMenu === true ? 'cross' : 'burger'}`}></Icon>
          </div>
          
          <Brand />
          <div className={styles.actionContainers}>
            <button
              aria-label="Search"
              className={`${styles.iconButton} ${styles.iconContainer}`}
              onClick={() => {
                setShowSearch(!showSearch);
              }}
            >
              <Icon symbol={'search'}></Icon>
            </button>
          
            <button
              aria-label="Favoritos"
              className={`${styles.iconButton} ${styles.iconContainer}`}
              onClick={() => navigate('/account/favorites')} // Correct usage of navigate
            >
              <Icon symbol={'heart'} />
            </button>
          
            <button
              aria-label="Ordenes"
              className={`${styles.iconButton} ${styles.iconContainer}`}
              onClick={() => navigate('/account')} // Correct usage of navigate
            >
              <Icon symbol={'user'} />
            </button>
            
            <button
              aria-label="Cart"
              className={`${styles.iconButton} ${styles.iconContainer} ${styles.bagIconContainer}`}
              onClick={() => {
                setShowMiniCart(true);
                setMobileMenu(false);
              }}
            >
              <Icon symbol={'bag'} />
              <div className={styles.bagNotification}>
                <span>{uniqueProductCount}</span>
              </div>
            </button>
            <div className={styles.notificationContainer}>
              <AddNotification openCart={() => setShowMiniCart(true)} />
            </div>
          </div>
        </div>

        {/* Search container */}
        <div
          className={`${styles.searchContainer} ${
            showSearch === true ? styles.show : styles.hide
          }`}
        >
          <h4>¿Qué buscas?</h4>
          <form className={styles.searchForm} onSubmit={(e) => handleSearch(e)}>
            <FormInputField
              ref={searchRef}
              icon={'arrow'}
              id={'searchInput'}
              value={search}
              placeholder={'Buscar productos...'}
              type={'text'}
              handleChange={(_, e) => setSearch(e)}
            />
            {/* Dropdown for search suggestions */}
            {filteredData.length > 0 && (
              <div className="absolute z-10 mt-2 w-full bg-white border rounded-md shadow-lg">
                <ul>
                  {filteredData.slice(0, 5).map((product, index) => (
                    <li
                      key={index}
                      onClick={() => handleSelect(product)}
                      className={styles.resultItem}
                    >
                      <img 
                        src={product.imagen} 
                        alt={product.nombre} 
                        className={styles.resultImage} 
                      />
                      <div className={styles.resultText}>
                        <p className={styles.brand}>{product.casa}</p>
                        <p className={styles.name}>{product.nombre}</p>
                        <p className={styles.tipo_perfume}>{product.tamanio}</p> 
                        <p className={styles.brand}>{product.tipo_perfume}</p>
                        
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </form>
          <div
            role={'presentation'}
            onClick={(e) => {
              e.stopPropagation();
              setShowSearch(false);
            }}
            className={styles.backdrop}
          ></div>
        </div>


      </Container>

      {/* menu container */}
      <div
        role={'presentation'}
        onMouseLeave={() => setShowMenu(false)}
        onMouseEnter={() => setShowMenu(true)}
        className={`${styles.menuContainer} ${
          showMenu === true ? styles.show : ''
        }`}
      >
        <Container size={'large'} spacing={'min'}>
          <ExpandedMenu menu={menu} />
        </Container>
      </div>

      {/* minicart container */}
      <Drawer visible={showMiniCart} close={() => setShowMiniCart(false)}>
        <MiniCart />
      </Drawer>

      {/* mobile menu */}
      <div className={styles.mobileMenuContainer}>
        <Drawer
          hideCross
          top={'98px'}
          isReverse
          visible={mobileMenu}
          close={() => setMobileMenu(false)}
        >
          <MobileNavigation close={() => setMobileMenu(false)} />
        </Drawer>
      </div>
    </div>
  );
};

export default Header;
