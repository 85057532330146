import React, { useState } from 'react';
import { validateEmail } from '../helpers/general';
import styles from './forgot.module.css';

import Layout from '../components/Layout/Layout';
import FormInputField from '../components/FormInputField/FormInputField';
import Button from '../components/Button';
import AttributeGrid from '../components/AttributeGrid';

const initialState = { email: '' };
const errorState = { email: '' };

const ForgotPage = () => {
  const [loginForm, setLoginForm] = useState(initialState);
  const [errorForm, setErrorForm] = useState(errorState);

  const handleChange = (id, e) => {
    setLoginForm((prev) => ({ ...prev, [id]: e }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateEmail(loginForm.email)) {
      setErrorForm({ email: 'Dirección de correo electrónico no válida' });
      return;
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/Cliente/forgot-password`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ Correo_electronico: loginForm.email }),
      });

      if (!response.ok) throw new Error('Error al enviar el correo de restablecimiento');

      alert('Se ha enviado un correo para restablecer tu contraseña.');
      setLoginForm(initialState);
      setErrorForm(errorState);
    } catch (error) {
      setErrorForm({ email: 'Algo salió mal. Por favor, inténtalo de nuevo.' });
    }
  };

  return (
    <Layout disablePaddingBottom>
      <div className={styles.root}>
        <h1 className={styles.title}>Restablecer Contraseña</h1>
        <p className={styles.message}>
          Ingresa tu correo electrónico para solicitar una nueva contraseña.
        </p>
        <form className={styles.formContainer} noValidate onSubmit={handleSubmit}>
          <FormInputField
            id="email"
            value={loginForm.email}
            handleChange={handleChange}
            type="email"
            labelName="Correo electrónico"
            error={errorForm.email}
          />
          <div className={styles.buttonContainer}>
            <Button fullWidth level="primary" type="submit">
              Restablecer Contraseña
            </Button>
          </div>
        </form>
      </div>
    </Layout>
  );
};

export default ForgotPage;
