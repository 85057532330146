import React, { useState } from 'react';
import Icon from '../Icons/Icon';
import styles from './Accordion.module.css';

const Accordion = (props) => {
  const { title, type = 'caret', customStyle, children, onToggle } = props;
  const [open, setOpen] = useState(false);
  
  const combinedStyling = { ...styles, ...customStyle };

  const handleToggle = () => {
    const newState = !open;
    setOpen(newState);
    if (onToggle) {
      onToggle(newState); // Notify parent when opened/closed
    }
  };

  const icon =
    type === 'caret' ? (
      <Icon symbol={'caret'} />
    ) : (
      <Icon symbol={`${open ? 'minus' : 'plus'}`} />
    );

  return (
    <div className={combinedStyling.accordionRoot}>
      <div
        className={`${combinedStyling.accordionHeader}`}
        role="presentation"
        onClick={handleToggle}
      >
        <span
          className={`${combinedStyling.accordionTitle} ${combinedStyling.uppercase}`}
        >
          {title}
        </span>
        <div
          className={`${combinedStyling.iconContainer} ${
            open ? styles.rotate : ''
          }`}
        >
          {icon}
        </div>
      </div>
      <div
        className={`${styles.accordionContent} ${
          open ? styles.show : styles.hide
        }`}
      >
        {children}
      </div>
    </div>
  );
};

export default Accordion;
