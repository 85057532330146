import { useState, useEffect } from 'react';
import Layout from '../../components/Layout/Layout';
import Modal from '../../components/Modal';
import Button from '../../components/Button';
import styles from './admin.module.css';
import { useNavigate } from 'react-router-dom';  

const UploadPage = () => {
  const navigate = useNavigate(); 
  const apiUrl = process.env.REACT_APP_API_URL;
  const [imageFiles, setImageFiles] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [uploadedImages, setUploadedImages] = useState([]);
  const [statusMessage, setStatusMessage] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [fileList, setFileList] = useState([]);
  const imagesPerPage = 15;

  const [showDelete, setShowDelete] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [imageToDelete, setImageToDelete] = useState(null);


  const refreshImages = async () => {
    try {
      const response = await fetch(`${apiUrl}/api/FileUpload/list-assets`);
      const data = await response.json();

      // Add dimensions and size to the image data
      const updatedImages = await Promise.all(data.map(async (image) => {
        const dimensions = await fetchImageDimensions(image.name);

        // Convert size from bytes to a more readable format (KB/MB)
        return { ...image, dimensions };
      }));

      setUploadedImages(updatedImages);
    } catch (error) {
      console.error('Error fetching images:', error);
    }
  };

  // Function to fetch image dimensions
  const fetchImageDimensions = (imageName) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => resolve(`${img.width}x${img.height}`);
      img.onerror = reject;
      img.src = `https://www.perfumeriayestiloglamour.com/assets/${imageName}`;
    });
  };
  
  // En `useEffect`
  useEffect(() => {
    refreshImages();
  }, []);


  const handleFileUpload = async (e) => {
    e.preventDefault();
  
    if (fileList.length === 0) {
      setSuccessMessage('Please select at least one image to upload.');
      setShowSuccess(true);
      return;
    }
  
    const formData = new FormData();
    Array.from(fileList).forEach((file) => formData.append('files', file));
  
    try {
      const response = await fetch(`${apiUrl}/api/FileUpload/upload`, {
        method: 'POST',
        body: formData,
      });
  
      if (response.ok) {
        refreshImages();
        setSuccessMessage('Files uploaded successfully!');
        setShowSuccess(true);
      } else {
        const errorData = await response.json();
        setSuccessMessage(errorData.Error || 'Failed to upload files.');
        setShowSuccess(true);
      }
    } catch (error) {
      setSuccessMessage('Error: ' + error.message);
      setShowSuccess(true);
    }
  };
  
  const handleDelete = async (imageName) => {
    if (!imageName) {
      setSuccessMessage('Please provide an image name.');
      setShowSuccess(true);
      return;
    }
  
    try {
      const response = await fetch(`${apiUrl}/api/FileUpload/delete/${imageName}`, {
        method: 'DELETE',
      });
  
      if (response.ok) {
        setUploadedImages((prevImages) => prevImages.filter(img => img.name !== imageName));
        setSuccessMessage('File deleted successfully!');
        setShowSuccess(true);
      } else {
        const errorData = await response.json();
        setSuccessMessage(errorData.Error || 'Failed to delete file.');
        setShowSuccess(true);
      }
    } catch (error) {
      setSuccessMessage('Error: ' + error.message);
      setShowSuccess(true);
    }
  };
  

  // Get the images to display for the current page
  const imagesToDisplay = uploadedImages
    .filter((image) =>
      image.name.toLowerCase().includes(searchTerm.toLowerCase())
    )
    .slice((currentPage - 1) * imagesPerPage, currentPage * imagesPerPage);

  // Handle the Next Page button click
  const handleNextPage = () => {
    if (imagesToDisplay.length === imagesPerPage) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  // Handle the Previous Page button click
  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const redirectToAdmin = () => {
    navigate('/admin');  // Redirect to /admin page
  };
  
  return (
    <Layout>
      {/* Confirmation Modal for Deleting an Address */}
      <Modal visible={showDelete} close={() => setShowDelete(false)}>
          <div className={styles.confirmDeleteContainer}>
            <h4>Delete Image?</h4>
            <p>Are you sure you want to delete this image? This action cannot be undone.</p>
            <div className={styles.actionContainer}>
              <Button onClick={() => { setShowDelete(false); handleDelete(imageToDelete); }} level="primary">
                Delete
              </Button>
              <Button onClick={() => setShowDelete(false)} level="secondary">
                Cancel
              </Button>
            </div>
          </div>
        </Modal>
        {/* Success Message Modal */}
        <Modal visible={showSuccess} close={() => setShowSuccess(false)}>
          <div className={styles.successMessageContainer}>
            <h4>Success</h4>
            <p>{successMessage}</p>
            <Button onClick={() => setShowSuccess(false)} level="primary">
              OK
            </Button>
          </div>
        </Modal>
      <div className={styles.adminFormContainer}>
        <h1 className={styles.adminTitle}>Administrar Imágenes</h1>
        {/* File Upload Form */}
        <form className={styles.adminForm} onSubmit={handleFileUpload}>
          <div>
            <label>Seleccionar Imágenes (.avif):</label>
            <input
              type="file"
              accept=".avif"
              multiple
              onChange={(e) => setFileList(e.target.files)} // Update fileList state with selected files
            />
          </div>
          <button type="submit">Subir Imágenes</button>
        </form>
        <div className={styles.adminTableContainer}>
          <h2 className={styles.subtitle}>Lista de Imágenes Subidas</h2>
          {/* Search Bar */}
          <input
            type="text"
            placeholder="Buscar imagen..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className={styles.searchInput}
          />
          <table className={styles.adminTable}>
            <thead>
              <tr>
                <th>Nombre</th>
                <th>Imagen</th>
                <th>Dimensiones</th>
                <th> </th>
              </tr>
            </thead>
            <tbody>
              {imagesToDisplay.map((image, index) => (
                <tr key={index}>
                  <td>{image.name}</td>
                  <td>
                    <img
                      src={`https://www.perfumeriayestiloglamour.com/assets/${image.name}`}
                      alt={image.name}
                      width="100"
                    />
                  </td>
                  <td>{image.dimensions}</td>
                  <td>
                    <button onClick={() => { 
                      setImageToDelete(image.name);
                      setShowDelete(true); 
                    }}>
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          {/* Pagination Buttons */}
          <div>
            <button onClick={handlePreviousPage} disabled={currentPage === 1}>
              Previous
            </button>
            <button onClick={handleNextPage} disabled={imagesToDisplay.length < imagesPerPage}>
              Next
            </button>
          </div>

          {/* Button to Redirect to /admin */}
          <div className={styles.topLeftButton}>
            <Button onClick={redirectToAdmin} level="primary">
              Go to Admin Page
            </Button>
          </div>
        </div>
      </div>
      </Layout>
    );
  
};

export default UploadPage;
