import React from 'react';
import styles  from './accountSuccess.module.css';

import ActionCard from '../components/ActionCard';
import Container from '../components/Container';
import Layout from '../components/Layout/Layout';

const OrderConfirmPage = (props) => {
  return (
    <Layout disablePaddingBottom>
      <Container size={'medium'}>
        <div className={styles.root}>
          <h1>¡Gracias!</h1>
          <p>
            Estamos procesando tu pedido. Si tienes alguna duda, no dudes en
            enviarnos un correo a support@perfumeriayestiloglamour.com
          </p>
          <div className={styles.actionContainer}>
            <ActionCard
              title={'Historial de pedidos'}
              icon={'delivery'}
              subtitle={'Verifica tus pedido anteriores'}
              link={'/account/orders'}
              size={'lg'}
            />

            <ActionCard
              title={'Comprar'}
              icon={'bag'}
              subtitle={'Continuar comprando'}
              link={'/shop'}
            />

            <ActionCard
              title={'Contáctanos'}
              icon={'phone'}
              subtitle={'Ponte en contacto con nosotros'}
              link={'/support#contact'}
            />
          </div>
        </div>
      </Container>
    </Layout>
  );
};

export default OrderConfirmPage;
