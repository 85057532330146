import React from 'react';

import AttributeGrid from '../components/AttributeGrid';
import Container from '../components/Container';
import Hero from '../components/Hero';
import BlogPreviewGrid from '../components/BlogPreviewGrid';
import Highlight from '../components/Highlight';
import Layout from '../components/Layout/Layout';
import ProductCollectionGrid from '../components/ProductCollectionGrid';
import Title from '../components/Title';
import Slider from '../components/Slider'; 
import { useState, useEffect } from "react";
import { generateMockBlogData } from '../helpers/mock';

import styles  from './index.module.css';
import { useNavigate } from 'react-router-dom';
import { newtonsCradle } from 'ldrs'



const IndexPage = () => {
  const navigate = useNavigate();
  //const newArrivals = generateMockProductData(3, 'shirt');
  const blogData = generateMockBlogData(3);
  const [loading, setLoading] = useState(true);

  newtonsCradle.register()

  function Loader() {
    return (
      <div className={styles.loadingContainer}>
        <l-newtons-cradle
          size="100"
          speed="0.8" 
          color="black" 
        ></l-newtons-cradle>
      </div>
    );
  }

  const goToShop = () => {
    navigate('/shop');
  };

  const handleSlideClick = (query) => {
    navigate(`/shop?q=${query}`);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
      console.log('Loading complete');
    }, 500); // Wait 2 seconds
  
    return () => clearTimeout(timer); // Cleanup timer on unmount
  }, []);
  


  return  loading ? <Loader /> : (
    <Layout disablePaddingBottom>
      {/* Hero Container */}
      <Hero
        image={'/bannerDior.avif'}
        title={' '}
        subtitle={' '}
        ctaText={'shop now'}
        ctaAction={goToShop}
        ctaButton= {{
          position: 'absolute',
          bottom: '20px', // Adjust this value to your desired vertical position
          right: '20px',  // Adjust this value to your desired horizontal position
          
        }}
      />

      {/* Message Container */}
      <div className={styles.messageContainer}>
        <p>

        </p>
        <p>
          En Perfumería y Estilo Glamour cuidamos cada detalle para que encuentres tu fragancia <span className={styles.gold}>perfecta</span>
          
        </p>
      </div>



      {/* Collection Container */}
      <div className={styles.collectionContainer}>
        <Container size={'large'}>
          <Title name={'Las Mejores Fragancias'} />
          <ProductCollectionGrid />
        </Container>
      </div>


      <div className={styles.blogsContainer}>
        <Container size={'large'}>
          <Title name={'Variedad de Colecciones'} subtitle={''} />
        </Container>
      </div>

      <Slider spacing={true}>
        <div className={styles.slideContainer} onClick={() => handleSlideClick('benetton')}>
          <img src="collections/Benneton.avif" alt="Slide 1" />
          <div className={styles.content}>
            <span className={styles.title}>Benneton</span>
          </div>
          <div className={styles.overlay}></div>
        </div>

        <div className={styles.slideContainer} onClick={() => handleSlideClick('Jean Paul')}>
          <img src="collections/Scandal.avif" alt="Slide 2" />
          <div className={styles.content}>
            <span className={styles.title}>Jean Paul Gaulthier</span>
          </div>
          <div className={styles.overlay}></div>
        </div>

        <div className={styles.slideContainer} onClick={() => handleSlideClick('Lattafa')}>
          <img src="collections/Lattafa-Asad.avif" alt="Slide 3" />
          <div className={styles.content}>
            <span className={styles.title}>Lattafa</span>
          </div>
          <div className={styles.overlay}></div>
        </div>

        <div className={styles.slideContainer} onClick={() => handleSlideClick('Dolce Gabbana')}>
          <img src="collections/dolce-gabanna.avif" alt="Slide 4" />
          <div className={styles.content}>
            <span className={styles.title}>Dolce Gabbana</span>
          </div>
          <div className={styles.overlay}></div>
        </div>

        <div className={styles.slideContainer} onClick={() => handleSlideClick('Marc Jacobs')}>
          <img src="collections/perfect_marc.avif" alt="Slide 5" />
          <div className={styles.content}>
            <span className={styles.title}>Marc Jacobs</span>
          </div>
          <div className={styles.overlay}></div>
        </div>
      </Slider>


      {/* Highlight  */}
      <div className={styles.highlightContainer}>
        <Container size={'large'} fullMobile>
          <Highlight
            image={'/5Qh5OQgs.avif'}
            altImage={'highlight image'}
            miniImage={'/logoBW.avif'}
            miniImageAlt={'mini highlight image'}
            title={'Su Perfumería por Excelencia'}
            description={`Descubre nuestra selección de perfumes de primera calidad.`}
            textLink={'shop now'}
            link={'/shop'}
          />
        </Container>
      </div>


      {/* Blog Grid */}
      <div className={styles.blogsContainer}>
        <Container size={'large'}>
          <Title name={'Lo último en fragancias'} subtitle={'Perfumería y Estilo Glamour ofrece las innovaciones en perfumes de las mejores casas'} />
          <BlogPreviewGrid data={blogData} />
        </Container>
      </div>


      {/* Social Media 
      <div className={styles.socialContainer}>
        <Title
          name={'Styled by You'}
          subtitle={'Tag @sydney to be featured.'}
        />
        <div className={styles.socialContentGrid}>
          <img src={`/social/socialMedia1.png`} alt={'social media 1'} />
          <img src={`/social/socialMedia2.png`} alt={'social media 2'} />
          <img src={`/social/socialMedia3.png`} alt={'social media 3'} />
          <img src={`/social/socialMedia4.png`} alt={'social media 4'} />
        </div>
      </div>*/}
      {/*<AttributeGrid />*/}
    </Layout>
  );
};

export default IndexPage;
