import React, { createContext, useContext, useState } from 'react';

const ProductContext = createContext();

export const ProductProvider = ({ children }) => {
  const [allData, setAllData] = useState([]);
  const [displayedData, setDisplayedData] = useState([]);

  return (
    <ProductContext.Provider value={{ allData, setAllData, displayedData, setDisplayedData }}>
      {children}
    </ProductContext.Provider>
  );
};

export const useProductContext = () => useContext(ProductContext);
