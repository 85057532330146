import React from 'react';
import { useNavigate } from 'react-router-dom'; // Use useNavigate instead of useHistory
import styles from './ActionCard.module.css';

import Icon from '../Icons/Icon';

const ActionCard = (props) => {
  const { title, icon, subtitle, link, size } = props;
  const navigate = useNavigate(); // Initialize the useNavigate hook

  const handleClick = () => {
    navigate(link); // Use navigate for navigation (no need for .push)
  };

  return (
    <div
      className={styles.root}
      role="presentation"
      onClick={handleClick} // Call handleClick on click
    >
      <div className={`${styles.iconContainer} ${styles[size]}`}>
        <Icon symbol={icon} />
      </div>
      <span className={styles.actionName}>{title}</span>
      <span className={styles.link}>
        {subtitle}
        <Icon symbol={'caret'} />
      </span>
    </div>
  );
};

export default ActionCard;
